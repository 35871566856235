import { useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";
import MetaMaskCard from "../connector-cards/metamask";
import CoinbaseWalletCard from "../connector-cards/coinbase-wallet-card";
import WalletConnectCard from "../connector-cards/walletconnect-card";
import { MAINNET_CHAINS } from "../../lib/connectors/chains";
import { Step, StepIcon, StepIndicator, StepNumber, StepStatus, StepTitle, Stepper, StepDescription, StepSeparator } from "@chakra-ui/stepper";
import { Heading, Text, VStack, HStack, Box, Wrap, WrapItem, Link } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { ChevronLeftIcon, ChevronRightIcon, WarningIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/button";
import { Hide } from "@chakra-ui/media-query";
import { Input } from "@chakra-ui/input";
import { Select } from "@chakra-ui/select";
import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/modal";
import { keyframes } from "@emotion/react";
import Meta from "../../Meta";

const steps = [
  { title: 'Start', description: 'Intro' },
  { title: 'Chain', description: 'Select the chain' },
  { title: 'Sacrifice', description: 'Send your Sacrifice' },
]

const fire = keyframes`
  0% { box-shadow: 0 0 5px #cc000033, 0 0 25px #cc000033, 0 0 50px #cc000033, 0 0 200px #cc000033;}
  20% { box-shadow: 0 0 5px #ff7700, 0 0 25px #ff7700, 0 0 50px #ff7700, 0 0 200px #ff7700;}
  40% { box-shadow: 0 0 5px #ffaa00, 0 0 25px #ffaa00, 0 0 50px #ffaa00, 0 0 200px #ffaa00;}
  60% { box-shadow: 0 0 5px #ff7700, 0 0 25px #ff7700, 0 0 50px #ff7700, 0 0 200px #ff7700;}
  80% { box-shadow: 0 0 5px #cc000033, 0 0 25px #cc000033, 0 0 50px #cc000033, 0 0 200px #cc000033;}
  100% { box-shadow: 0 0 5px #cc000033, 0 0 25px #cc000033, 0 0 50px #cc000033, 0 0 200px #cc000033;}
`

export default function BlockchainPay({ chainId, chainName, allowNative, allowTokens }) {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ amount, setAmount ] = useState(1.0);
  const [ token, setToken ] = useState({ native: true });
  return (
    <>
      <Meta title="Sacrifice">
        Sacrifice for the Firechain and become part of a new journey!
      </Meta>    
      <VStack w="full" alignItems="flex-start">
        <VStack gap={[4,8,16]} w="7xl" maxW="100%">
          <Heading as={HStack} fontSize="2xl">
            <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
            <Text as="span">
              Welcome to the Firechain Sacrifice Phase
            </Text>
          </Heading>
          <Text>
            The Firechain Sacrifice is creating a true decentralized ecosystem for and build by the people. Build from zero, with zero.
          </Text>

          <VStack mb={[4,8,16]} borderWidth="1px" bg="blackAlpha.50" borderColor="blackAlpha.500" borderRadius={[0,4,8]} w="full" alignItems="flex-start" gap={[1,2,4,8]}>          
            <Stepper size={["sm","md"]} p={[2,4,8]} bg="linear-gradient(0deg, rgba(0,0,0,0.0) 0%, #ecd35033 1%, rgba(255,255,255,0.5) 10%, rgba(255,255,255,1) 95%, #ecd350cc 100%)" w="full" index={2} colorScheme="orange" borderTopRadius={[0,4,8]}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink='0'>
                    <StepTitle>{step.title}</StepTitle>
                    <Hide below="md"><StepDescription>{step.description}</StepDescription></Hide>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>

            <VStack w="full" p={[2,4,8]} gap={[2,4,8]}>
              
              <Text textAlign="center" fontSize="2xl" fontWeight="bold">
                Send your
                <Button px={2} size="lg" variant="link" leftIcon={<Image w={4} h={4} src={`/thefirechain.com/images/logos/${MAINNET_CHAINS[chainId].nativeCurrency.symbol.toLowerCase()}.png`} />}>
                  {MAINNET_CHAINS[chainId].nativeCurrency.symbol} on {chainName}
                </Button>
                or tokens to the following address.<br /><Text as="u">Sending from exchanges is invalid for {chainName} sacrifices.</Text>
              </Text>
                    
              <HStack alignItems="flex-end">
                <VStack alignItems="flex-start" gap={0} spacing={0}>
                  <Text fontSize="sm">
                    Select token:
                  </Text>
                  <Select onChange={(e) => setToken(JSON.parse(e.currentTarget.value))}>
                    {allowNative && <option selected value={JSON.stringify({"native":true})}>{MAINNET_CHAINS[chainId].nativeCurrency.symbol} (native)</option>}
                    {allowTokens.map((it, index) => <option key={index} value={JSON.stringify(it)}>{it.name}</option>)}
                  </Select>
                </VStack>
                <VStack alignItems="flex-start" gap={0} spacing={0}>
                  <Text fontSize="sm">
                    Amount to sacrifce:
                  </Text>
                  <Input onKeyUp={e => setAmount(e.currentTarget.value)} placeholder="1.0" />
                </VStack>
                <Button bg="red.500" _hover={{ bg: "red.800" }} color="red.100" animation={`${fire} 2s infinite`} onClick={onOpen}>Send</Button>
              </HStack>

              <Text textAlign="center">
                You can send your crypto directly to the address, or you can connect your wallet to the Dapp and select the token you want to send for FIRE.
                You can enter more then once, in case you might want more FIRE.
              </Text>
              
              <CopyToClipboard
                text="0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4"
                onCopy={() =>
                  alert(
                    "Copied: 0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4"
                  )
                }
              >
                <HStack bg="blackAlpha.100" borderRadius={[2,4,8]} p={[1,2,4]} maxW="100%" overflow="hidden">
                  <FaCopy
                    color="black"
                    className="fas fa-copy fa-copy:before"
                  />
                  <Text textOverflow="ellipsis">0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4</Text>
                </HStack>
              </CopyToClipboard>

              <Wrap w="full" justify="center">
                <WrapItem>
                  <Text>
                    Only sacrifice from an address which you have private keys to. Do not send from exchanges and do not send any other tokens than the following on {MAINNET_CHAINS[chainId].nativeCurrency.symbol}:
                  </Text>
                </WrapItem>
                {allowTokens.map((it, index) => (
                  <WrapItem key={index}>
                    <Button as={Link} variant="link"
                      target="_blank"
                      rel="noreferrer"
                      href={`${MAINNET_CHAINS[chainId].blockExplorerUrls[0]}/${MAINNET_CHAINS[chainId].blockExplorerContractLookup ?? "token"}/${it.address}`}
                    >
                      {it.symbol} ({it.name})
                    </Button>
                    {index < allowTokens.length - 1 ? <>,&nbsp;&nbsp;</> : ""}
                  </WrapItem>
                ))}
              </Wrap>
              
            </VStack>

            <HStack p={[2,4,8]} w="full" justifyContent="space-between">
              <Button colorScheme="green" variant="outline" size="lg" as={Link} href="/step2" leftIcon={<ChevronLeftIcon />}>
                Back
              </Button>
              <Button colorScheme="green" size="lg" as={Link} href="/complete" rightIcon={<ChevronRightIcon />}>
                Continue
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Sacrifice</ModalHeader>
          <ModalCloseButton />
          <ModalBody as={VStack} gap={[2,4,8]} alignItems="flex-start" mb={4}>           
            <Text fontSize="xl" fontWeight="bold">
              Connect and send with one of our available wallet providers:
            </Text>

            <VStack w="full">
              <MetaMaskCard wide onPaid={() => navigate("/complete")} withSend desiredChainId={chainId} isSendingETH={!!token.native} amount={amount ?? 1.0} toAddress="0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4" tokenAddress={token.address} tokenDecimals={token.decimals ?? 18} />
              <CoinbaseWalletCard wide onPaid={() => navigate("/complete")} withSend desiredChainId={chainId} isSendingETH={!!token.native} amount={amount ?? 1.0} toAddress="0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4" tokenAddress={token.address} tokenDecimals={token.decimals ?? 18} />
              <WalletConnectCard wide onPaid={() => navigate("/complete")} withSend desiredChainId={chainId} isSendingETH={!!token.native} amount={amount ?? 1.0} toAddress="0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4" tokenAddress={token.address} tokenDecimals={token.decimals ?? 18} />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
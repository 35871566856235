import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Main from "./pages/main"
import SocialGenerator from "./pages/social-generator"

window.Buffer = window.Buffer || require("buffer").Buffer

class App extends Component {
  render() {
    return (
      <>
        <Helmet>
          <link rel="icon" href="/fancyshice.com/favicon.svg" />
          <meta charset="utf8" />
          <title>[ˈfænsɪʃaɪs]</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="stylesheet" href="/fancyshice.com/style.css" />     
        </Helmet>
        <h1><a href="/">[ˈfænsɪʃaɪs]</a></h1>
        <Router>
          <Routes>
            <Route exact path="/" element={<Main />}></Route>
            <Route exact path="/social-generator" element={<SocialGenerator />}></Route>
          </Routes>
        </Router>
      </>
    )
  }
}
export default App

const ETH = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
}

const MATIC = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
}

const CELO = {
  name: 'Celo',
  symbol: 'CELO',
  decimals: 18,
}

const DOGE = {
  name: 'Dogecoin',
  symbol: 'DOGE',
  decimals: 18,
}

const ETC = {
  name: 'ETC Token',
  symbol: 'ETC',
  decimals: 18,
}

const XDAI = {
  name: 'xDai Token',
  symbol: 'xDai',
  decimals: 18,
}

const ONE = {
  name: 'Harmony Token',
  symbol: 'ONE',
  decimals: 18,
}

const HT = {
  name: 'Heco Token',
  symbol: 'HT',
  decimals: 18,
}

const METIS = {
  name: 'Metis Token',
  symbol: 'METIS',
  decimals: 18,
}

const AVAX = {
  name: 'Avalanche',
  symbol: 'AVAX',
  decimals: 18,
}

const CRO = {
  name: 'Cronos',
  symbol: 'CRO',
  decimals: 18,
}

const FTM = {
  name: 'Fantom',
  symbol: 'FTM',
  decimals: 18,
}

const BNB = {
  name: 'BNB',
  symbol: 'BNB',
  decimals: 18,
}

function isExtendedChainInformation(chainInformation) {
  return !!chainInformation?.nativeCurrency
}

export function getAddChainParameters(chainId) {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  } else {
    return chainId
  }
}

const getInfuraUrlFor = (network) =>
  process.env.infuraKey ? `https://${network}.infura.io/v3/${process.env.infuraKey}` : undefined
const getAlchemyUrlFor = (network) =>
  process.env.alchemyKey ? `https://${network}.alchemyapi.io/v2/${process.env.alchemyKey}` : undefined

export const MAINNET_CHAINS = {
  1: {
    urls: [getInfuraUrlFor('mainnet'), getAlchemyUrlFor('eth-mainnet'), 'https://cloudflare-eth.com'].filter(Boolean),
    name: 'Ethereum',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://etherscan.io'],
  },
  10: {
    urls: [getInfuraUrlFor('optimism-mainnet'), 'https://mainnet.optimism.io'].filter(Boolean),
    name: 'Optimism',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  },
  25: {
    urls: ['https://node.croswap.com/rpc'],
    name: 'Cronos',
    nativeCurrency: CRO,
    blockExplorerUrls: ['https://cronoscan.com'],
  },
  56: {
    urls: ['https://bsc-dataseed.binance.org'],
    name: 'BNB Smart Chain',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://bscscan.com'],
  },
  61: {
    urls: ["https://www.ethercluster.com/etc"],
    name: 'Ethereum Classic',
    nativeCurrency: ETC,
    blockExplorerUrls: ['https://blockscout.com/etc/mainnet'],
  },
  100: {
    urls: ["https://rpc.gnosischain.com"],
    name: 'Gnosis',
    nativeCurrency: XDAI,
    blockExplorerUrls: ['https://gnosisscan.io'],
  },
  128: {
    urls: ["https://http-mainnet.hecochain.com"],
    name: 'Heco',
    nativeCurrency: HT,
    blockExplorerUrls: ['https://hecoinfo.com'],
  },
  137: {
    urls: [getInfuraUrlFor('polygon-mainnet'), 'https://polygon-rpc.com'].filter(Boolean),
    name: 'Polygon',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  250: {
    urls: ['https://rpc.ankr.com/fantom'],
    name: 'Fantom',
    nativeCurrency: FTM,
    blockExplorerUrls: ['https://ftmscan.com'],
  },
  1088: {
    urls: ['https://andromeda.metis.io/?owner=1088'],
    name: 'Metis',
    nativeCurrency: METIS,
    blockExplorerUrls: ['https://andromeda-explorer.metis.io'],
  },
  2000: {
    urls: ["https://rpc.dogechain.dog"],
    name: 'Dogechain',
    nativeCurrency: DOGE,
    blockExplorerUrls: ['https://explorer.dogechain.dog'],
  },
  42161: {
    urls: [getInfuraUrlFor('arbitrum-mainnet'), 'https://arb1.arbitrum.io/rpc'].filter(Boolean),
    name: 'Arbitrum One',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  42220: {
    urls: ['https://forno.celo.org'],
    name: 'Celo',
    nativeCurrency: CELO,
    blockExplorerUrls: ['https://explorer.celo.org'],
  },
  43114: {
    urls: ['https://api.avax.network/ext/bc/C/rpc'],
    name: 'Avalanche',
    nativeCurrency: AVAX,
    blockExplorerUrls: ['https://snowtrace.io'],
  },
  1666600000: {
    urls: ['https://api.harmony.one'],
    name: 'Harmony',
    nativeCurrency: ONE,
    blockExplorerUrls: ['https://explorer.harmony.one'],
    blockExplorerContractLookup: 'address',
  },
}

export const TESTNET_CHAINS = {
  5: {
    urls: [getInfuraUrlFor('goerli')].filter(Boolean),
    name: 'Görli',
  },
  420: {
    urls: [getInfuraUrlFor('optimism-goerli'), 'https://goerli.optimism.io'].filter(Boolean),
    name: 'Optimism Goerli',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://goerli-explorer.optimism.io'],
  },
  421613: {
    urls: [getInfuraUrlFor('arbitrum-goerli'), 'https://goerli-rollup.arbitrum.io/rpc'].filter(Boolean),
    name: 'Arbitrum Goerli',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://testnet.arbiscan.io'],
  },
  80001: {
    urls: [getInfuraUrlFor('polygon-mumbai')].filter(Boolean),
    name: 'Polygon Mumbai',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
  44787: {
    urls: ['https://alfajores-forno.celo-testnet.org'],
    name: 'Celo Alfajores',
    nativeCurrency: CELO,
    blockExplorerUrls: ['https://alfajores-blockscout.celo-testnet.org'],
  },
}

export const CHAINS = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
}

export const URLS = Object.keys(CHAINS).reduce(
  (accumulator, chainId) => {
    const validURLs = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)
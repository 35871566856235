import { Button, Alert, AlertIcon, Flex, HStack } from "@chakra-ui/react"

export default function TopAlert() {
  return (
    <Alert status='info' variant='top-accent' colorScheme="gray">
      <HStack alignItems="flex-start">
        <AlertIcon />
        <Flex alignItems={["flex-start","center"]} direction={["column","row"]} gap={1}>
          The Sacrifice Phase is active! Visit:
          <Button variant="link" as="a" href="https://info.thefirechain.com">info.thefirechain.com</Button>
        </Flex>
      </HStack>
    </Alert>
  )
}
import { useEffect, useState } from 'react'

import { coinbaseWallet, hooks } from '../../lib/connectors/coinbaseWallet'
import { Card } from '../connector-card'

const { useChainId, useAccounts, useIsActivating, useIsActive, useProvider, useENSNames } = hooks

export default function CoinbaseWalletCard({ wide, inline, withSend, desiredChainId, isSendingETH, amount, toAddress, tokenAddress, tokenDecimals, onContinue, onPaid }) {
  const chainId = useChainId()
  const accounts = useAccounts()
  const isActivating = useIsActivating()

  const isActive = useIsActive()

  const provider = useProvider()
  const ENSNames = useENSNames(provider)

  const [error, setError] = useState(undefined)

  // attempt to connect eagerly on mount
  useEffect(() => {
    void coinbaseWallet.connectEagerly().catch(() => {
      console.debug('Failed to connect eagerly to coinbase wallet')
    })
  }, [])

  return (
    <Card
      wide={wide}
      connector={coinbaseWallet}
      activeChainId={chainId}
      isActivating={isActivating}
      isActive={isActive}
      error={error}
      setError={setError}
      accounts={accounts}
      provider={provider}
      ENSNames={ENSNames}
      desiredChainId={desiredChainId}
      isSendingETH={isSendingETH}
      amount={amount}
      toAddress={toAddress}
      tokenAddress={tokenAddress}
      tokenDecimals={tokenDecimals}
      icon="/thefirechain.com/images/coinbase-wallet.svg"
      withSend={withSend}
      inline={inline}
      onContinue={onContinue}
      onPaid={onPaid}
    />
  )
}
import { Button, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function SacInfo() {
  return (
    <VStack w="full" alignItems="flex-start">
      <Heading>Sacrifice Information</Heading>
      
      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          How do I get FIRE?
        </Text>
      </Heading>
      <Text>For every $1 send you'll receive 10 FIRE as an airdrop when the Firechain mainnet launches</Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Get FIRE before mainnet launches
        </Text>
      </Heading>
      <Text>
        You can publicly view everyones stats during the sacrifice period by checking the public balances of the sacrifice addresses on
        <Button pl={1} variant="link" as="a" href="https://moonscan.com/sacrifice/firechain-public" target="_blank">moonscan.com</Button>
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          What is the total supply?
        </Text>
      </Heading>
      <Text>
        42,000,000 FIRE.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          How many coins do I get?
        </Text>
      </Heading>
      <Text>Max amount during the sacrifice phase is limited to 21,000,000 coins at a first come, first serve base.</Text>
    </VStack>
  )
}

import React from "react";
import BlockChainPay from "./index"

const TOKENS = [
  {
    "name": "AAVE",
    "symbol": "AAVE",
    "address": "0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B"
  },
  {
    "name": "DAI Stablecoin",
    "symbol": "DAI",
    "address": "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e"
  },
  {
    "name": "Fantom BNB",
    "symbol": "FBNB",
    "address": "0x27f26f00e1605903645bbabc0a73e35027dccd45"
  },
  {
    "name": "Chainlink",
    "symbol": "LINK",
    "address": "0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8"
  },
  {
    "name": "USD Coin",
    "symbol": "USDC",
    "address": "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
    "decimals": 6
  },
  {
    "name": "Wrapped Fantom",
    "symbol": "WFTM",
    "address": "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
  }
]

export default function Fantom() {
  return <BlockChainPay chainId={250} chainName="FANTOM" allowNative allowTokens={TOKENS} />
}
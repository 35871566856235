import React, { useEffect, useState } from 'react'
import { Button, ChakraProvider, VStack } from '@chakra-ui/react'
import theme from "./theme"

import Fallback from "./domains/_fallback"
import InfoThefirechainCom from "./domains/info.thefirechain.com"
import ThefirechainCom from "./domains/thefirechain.com"
import BpLa from "./domains/bp.la"
import FancyshiceCom from "./domains/fancyshice.com"

const HOST_MAP = {
  _fallback: Fallback,
  "bp.la": BpLa,
  "www.bp.la": BpLa,
  "7bp.de": BpLa,
  "www.7bp.de": BpLa,
  "fancyshice.com": FancyshiceCom,
  "www.fancyshice.com": FancyshiceCom,
  "stadel-app-zrdev.ondigitalocean.app": InfoThefirechainCom,
  "192.168.11.241": ThefirechainCom,
  "info.thefirechain.com": InfoThefirechainCom,
  "thefirechain.com": ThefirechainCom,
  "www.thefirechain.com": ThefirechainCom,
}

export default function App() {
  const [ domain, setDomain ] = useState("")

  useEffect(() => {
    setDomain(window.location.hostname)
  }, [])

  if (!domain) {
    return (
      <ChakraProvider theme={theme}>
        <VStack alignItems="center" justifyContent="center" minH="100vh" minW="100vw">
          <Button variant="link" isLoading loadingText="Loading..." />
        </VStack>
      </ChakraProvider>
    )
  }

  const Stadel = HOST_MAP[domain] ?? HOST_MAP[0]

  return (
    <ChakraProvider theme={theme}>
      <Stadel />
    </ChakraProvider>
  )
}
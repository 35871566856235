import React from "react";

import StepIcons from "../../_shared/firechain/comp/icons/StepIcons";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Heading, Text, VStack, HStack, Link } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import Meta from "../../_shared/firechain/Meta";
import Steps from "../comp/steps";

function Step2() {
  return (
    <>
      <Meta title="Sacrifice">
        Sacrifice for the Firechain and become part of a new journey!
      </Meta>
      <VStack w="full" alignItems="flex-start">
        <VStack gap={[4,8,16]} maxW="7xl">
          <Heading as={HStack} fontSize="2xl">
            <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
            <Text as="span">
              Welcome to the Firechain Sacrifice Phase
            </Text>
          </Heading>
          <Text>
            The Firechain Sacrifice is creating a true decentralized ecosystem for and build by the people. Build from zero, with zero.
          </Text>

          <VStack mb={[4,8,16]} borderWidth="1px" bg="blackAlpha.50" borderColor="blackAlpha.500" borderRadius={[0,4,8]} w="full" alignItems="flex-start" gap={[1,2,4,8]}>          
            <Steps index={1} />

            <VStack w="full" p={[2,4,8]} gap={[2,4,8]}>
              
              <Text textAlign="center" fontSize="2xl" fontWeight="bold">
                Select the network which you want to use to get Fired up!
              </Text>
              
              <StepIcons withLinks />
            </VStack>
            
            <VStack p={[2,4,8]} w="full" alignItems="flex-start">
              <Button colorScheme="green" variant="outline" size="lg" as={Link} href="/" leftIcon={<ChevronLeftIcon />}>
                Back
              </Button>
            </VStack>

          </VStack>
        </VStack>
      </VStack>
    </>
  )
}

export default Step2

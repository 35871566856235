import { VStack, Box } from "@chakra-ui/react"
import Header from "./comp/Header"

export default function Layout({ children, announcement, navItems }) {
  return (
    <VStack w="100%" justifyContent="flex-start" maxW="100%">
      <Header announcement={announcement} navItems={navItems} />
      <Box maxW="7xl" w="100%" mx="auto" overflow="auto" p={[2,4,8]}>
        {children}
      </Box>
    </VStack>
  )
}
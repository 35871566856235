import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Heading, HStack, VStack, Text, Wrap, WrapItem, Button, Image } from "@chakra-ui/react";
import React from "react";

export default function FounderInfo() {
  return (
    <VStack w="full" alignItems="flex-start">     
      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          More from Kryptonacci
        </Text>
      </Heading>
      <Wrap>
        <WrapItem>
          <Button borderRadius="full" pl={1} leftIcon={
            <Image src="/thefirechain.com/images/icons/twitter.png" w={8} h={8} />
          } rightIcon={<ExternalLinkIcon />} as="a" href="https://twitter.com/kryptonacci" target="_blank">Twitter</Button>
        </WrapItem>
        <WrapItem>
          <Button borderRadius="full" pl={1} leftIcon={
            <Image src="/thefirechain.com/images/icons/medium.png" w={8} h={8} />
          } rightIcon={<ExternalLinkIcon />} as="a" href="https://medium.com/@kryptonacci" target="_blank">Medium</Button>
        </WrapItem>
        <WrapItem>
          <Button borderRadius="full" pl={1} leftIcon={
            <Image src="/thefirechain.com/images/icons/discord.png" w={8} h={8} />
          } rightIcon={<ExternalLinkIcon />} as="a" href="https://discord.com/invite/FVaTXyNnCS" target="_blank">Discord</Button>
        </WrapItem>
        <WrapItem>
          <Button borderRadius="full" pl={1} leftIcon={
            <Image src="/thefirechain.com/images/icons/telegram.png" w={8} h={8} />
          } rightIcon={<ExternalLinkIcon />} as="a" href="https://t.me/IAmTheMessiah" target="_blank">Telegram</Button>
        </WrapItem>
      </Wrap>
    </VStack>
  )
}

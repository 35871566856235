import { Box, Button, Divider, Stack } from "@chakra-ui/react"

export default function Footer() {
  return (
    <Box mt={[4,8,16]}>
      <Divider />
      <Stack direction={["column","row"]} w="full" alignItems="center">
        <Button variant="link" as="a" href="/disclaimer">Disclaimer</Button>
        <Box borderLeft="1px solid rgba(0,0,0,0.2)" pl={2}>
          Copyright © 2023. All Rights Reserved | Powered by Firechain
        </Box>
      </Stack>
    </Box>
  )
}
import React from "react";
import BlockChainPay from "./index"

const TOKENS = [
  {
    "symbol": "AAVE.e",
    "address": "0x63a72806098bd3d9520cc43356dd78afe5d386d9",
    "name": "AAVE"
  },
  {
    "symbol": "BUSD.e",
    "address": "0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98",
    "name": "Binance-peg USD"
  },
  {
    "symbol": "DAI.e",
    "address": "0xd586e7f844cea2f87f50152665bcbc2c279d8d70",
    "name": "Dai Stablecoin"
  },
  {
    "symbol": "LINK.e",
    "address": "0x5947bb275c521040051d82396192181b413227a3",
    "name": "Chainlink"
  },
  {
    "symbol": "USDC.e",
    "address": "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    "name": "USD Coin",
    "decimals": 6
  },
  {
    "symbol": "USDT.e",
    "address": "0xc7198437980c041c805a1edcba50c1ce5db95118",
    "name": "Tether USD",
    "decimals": 6
  },
  {
    "symbol": "WETH.e",
    "address": "0xc7198437980c041c805a1edcba50c1ce5db95118",
    "name": "Wrapped Ether",
    "decimals": 6
  }
]

export default function Avalanche() {
  return <BlockChainPay chainId={43114} chainName="AVALANCHE" allowNative allowTokens={TOKENS} />
}
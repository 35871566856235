import React from "react";
import BlockChainPay from "./index"

const TOKENS = [
  {
    "name": "AAVE (PoS)",
    "symbol": "AAVE",
    "address": "0xd6df932a45c0f255f85145f286ea0b292b21c90b"
  },
  {
    "name": "CEL (PoS) (Celsius (PoS))",
    "symbol": "CEL",
    "address": "0xd85d1e945766fea5eda9103f918bd915fbca63e6",
    "decimals": 4
  },
  {
    "name": "DAI (PoS)",
    "symbol": "DAI",
    "address": "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063"
  },
  {
    "name": "HEX (PoS)",
    "symbol": "HEX",
    "address": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "decimals": 8
  }, 
  {
    "name": "LINK (PoS) (ChainLink (PoS))",
    "symbol": "LINK",
    "address": "0xb0897686c545045afc77cf20ec7a532e3120e0f1"
  },
  {
    "name": "Matic Token (Polygon/Matic)",  
    "symbol": "MATIC",
    "address": "0x0000000000000000000000000000000000001010"
  },
  {
    "name": "miMatic",
    "symbol": "miMATIC",
    "address": "0xa3fa99a148fa48d14ed51d610c367c61876997f1"
  },
  {
    "name": "QUICK (QuickSwap)",
    "symbol": "QUICK",
    "address": "0x831753dd7087cac61ab5644b308642cc1c33dc13"
  },
  {
    "name": "USDC (PoS) (USD Coin (PoS))",
    "symbol": "USDC",
    "address": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "decimals": 6
  },
  {
    "name": "USDT (PoS) (Tether USD (PoS)(USDT))",
    "symbol": "USDT",
    "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    "decimals": 6
  },
  {
    "name": "WBTC (PoS)",
    "symbol": "WBTC",
    "address": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "decimals": 8
  },
  {
    "name": "WETH (PoS) (Wrapped Ether (PoS))",
    "symbol": "WETH",
    "address": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619"
  },
  {
    "name": "WMATIC (Wrapped Matic)",
    "symbol": "WMATIC",
    "address": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
  }
]


export default function Polygon() {
  return <BlockChainPay chainId={137} chainName="POLYGON" allowNative allowTokens={TOKENS} />
}
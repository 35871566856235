import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Button, Heading, Flex, Image, Text, VStack, HStack } from "@chakra-ui/react"
import React from "react"
import { ButtonGroup } from "react-bootstrap"

export default function HelpOut() {
  return (
    <VStack p={[2,4,8]} mb={[4,8,16]} bg="blackAlpha.100" borderRadius={[0,4,8]} w="full" alignItems="flex-start">
      <Heading as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Happy to help
        </Text>
      </Heading>
      <Text>
        In case you are planning to publish any kind of advertisement for <Text as="b">thefirechain.com</Text> consult the
        <Button as="a" href="https://t.me/Donaswaptg" target="_blank" variant="link" px={1}>Telegram Chat</Button>
      </Text>
      <Text>
        There are a few cool and skilled designers from the community who are
        happy to do design work for you! Also a ton of artworks are in there for
        inspiration.
      </Text>        
      <ButtonGroup as={Flex} gap={2}>
        <Button bg="white" as="a" variant="outline" rightIcon={<ExternalLinkIcon />} href="https://t.me/donaswaptg" target="_blank">Open @Donaswaptg</Button>
        <Button bg="white" as="a" variant="outline" rightIcon={<ExternalLinkIcon />} href="https://t.me/thefirechain" target="_blank">Open @TheFirechain</Button>
      </ButtonGroup>
    </VStack>
  )
}

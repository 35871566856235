import React from "react";
import BlockChainPay from "./index"

const TOKENS = [
  {
    "name": "UNI",
    "symbol": "Uniswap",
    "address": "0x6fd9d7ad17242c41f7131d257212c54a0e816691"
  },
  {
    "name": "USDC",
    "symbol": "USD Coin",
    "address": "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
    "decimals": 6
  },
  {
    "name": "USDT",
    "symbol": "Tether USD",
    "address": "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
    "decimals": 6
  },
  {
    "name": "WBTC",
    "symbol": "Wrapped BTC",
    "address": "0x68f180fcce6836688e9084f035309e29bf0a2095",
    "decimals": 8
  },
  {
    "name": "WETH",
    "symbol": "Wrapped Ether",
    "address": "0x4200000000000000000000000000000000000006"
  }
]

export default function Avalanche() {
  return <BlockChainPay chainId={10} chainName="OPTIMISM" allowNative allowTokens={TOKENS} />
}
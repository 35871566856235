export function Status({
  isPaying,
  isActivating,
  isActive,
  error,
}) {
  return (
    <div>
      {error ? (
        <>
          🔴 {error.name ?? 'Error'}
          {error.message ? `: ${error.message}` : null}
        </>
      ) : isActivating ? (
        <>🟡 Connecting</>
      ) : isPaying ? (
        <>Waiting...</>
      ) : isActive ? (
        <>🟢 Connected</>
      ) : (
        <button class="rounded-md bg-transparent border-1 border-gray-400 color-400 px-2">Connect</button>
      )}
    </div>
  )
}
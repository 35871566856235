import React from "react"
import { Helmet } from "react-helmet"

export default function() {
  return (
    <>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/bp.la/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/bp.la/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/bp.la/favicon-16x16.png" />
        <link rel="mask-icon" href="/bp.la/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ffffff" />
        <title>bp.la - Benjamin Philipp</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css" />     
        <link rel="stylesheet" href="/bp.la/style.main.css" />     
      </Helmet>
      <main>
        <ul className="b">
          <li>Web-Developer</li>
          <li>App-Developer</li>
          <li>Software-Developer</li>
          <li>Full-Stack</li>
          <li>Shopware</li>
          <li>PHP</li>
          <li>JavaScript</li>
          <li>Java</li>
          <li>Server</li>
          <li>Linux</li>
          <li>Nginx</li>
          <li>Apache</li>
          <li>Wordpress</li>
          <li>iOS</li>
          <li>Android</li>
          <li>XCode</li>
          <li>Android Studio</li>
          <li>Mobile</li>
          <li>React</li>
          <li>ReactJS</li>
          <li>React Native</li>
          <li>flow</li>
          <li>Redux</li>
          <li>NodeJS</li>
          <li>yarn</li>
        </ul>
        <ul className="m">
          <li>Benjamin Philipp</li>
          <li>App- & Web-Developer</li>
          <li className="c p">Call <em onclick="copyToClipboard(this)">+49 2237 6969819</em></li>
          <li className="c">Mail <em onclick="copyToClipboard(this)">&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#098;&#112;&#046;&#108;&#097;</em></li>
          <li className="c p ns">XING <a href="https://www.xing.com/profile/Benjamin_Philipp3" onclick="var a=window.open();a.opener=null;a.location=this.href;return false;"><em>Benjamin_Philipp3</em></a></li>
          <li className="c ns">LinkedIn <a href="https://www.linkedin.com/in/bphilipp" onclick="var a=window.open();a.opener=null;a.location=this.href;return false;"><em>bphilipp</em></a></li>
          <li className="c ns">GitHub <a href="https://github.com/7bp" onclick="var a=window.open();a.opener=null;a.location=this.href;return false;"><em>7bp</em></a></li>
        </ul>
        <img src="/bp.la/bpla.png" />
      </main>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js"></script>
      <script src="/bp.la/script.js"></script>
    </>
  )
}
import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";

import Step1 from "./pages/Step1";
import Step2 from "./pages/Step2";

import Avalanche from "../_shared/firechain/comp/blockchains/Avalanche";
import BinanceSmartChain from "../_shared/firechain/comp/blockchains/BinanceSmartChain";
import Cronos from "../_shared/firechain/comp/blockchains/Cronos";
import Ethereum from "../_shared/firechain/comp/blockchains/Ethereum";
import Fantom from "../_shared/firechain/comp/blockchains/Fantom";
import Heco from "../_shared/firechain/comp/blockchains/Heco";
import Optimism from "../_shared/firechain/comp/blockchains/Optimism";
import Polygon from "../_shared/firechain/comp/blockchains/Polygon";
import Footer from "../_shared/firechain/comp/Footer";
import Disclaimer from "../_shared/firechain/pages/Disclaimer";
import Layout from "../_shared/firechain/Layout";
import Complete from "./pages/Complete";

import {
  coinbaseWallet,
  hooks as coinbaseWalletHooks,
} from "../_shared/firechain/lib/connectors/coinbaseWallet";
import { hooks as metaMaskHooks, metaMask } from "../_shared/firechain/lib/connectors/metaMask";
import {
  hooks as walletConnectHooks,
  walletConnect,
} from "../_shared/firechain/lib/connectors/walletConnect";
import { Box } from "@chakra-ui/react";

window.Buffer = window.Buffer || require("buffer").Buffer;

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

const NAV_ITEMS = [
  {
    label: 'Sacrifice',
    href: '/',
  },
];

class App extends Component {
  render() {
    return (
      <Web3ReactProvider connectors={connectors}>
        <Box minW="100%" minH="100vh" bg="white" color="black">
          <Layout navItems={NAV_ITEMS}>
            <Router>
              <Routes>
                <Route exact path="/" element={<Step1 />}></Route>
                <Route exact path="/step2" element={<Step2 />}></Route>
                <Route exact path="/avalanche" element={<Avalanche />}></Route>
                <Route exact path="/bsc" element={<BinanceSmartChain />}></Route>
                <Route exact path="/cronos" element={<Cronos />}></Route>
                <Route exact path="/ethereum" element={<Ethereum />}></Route>
                <Route exact path="/fantom" element={<Fantom />}></Route>
                <Route exact path="/heco" element={<Heco />}></Route>
                <Route exact path="/optimism" element={<Optimism />}></Route>
                <Route exact path="/matic" element={<Polygon />}></Route>
                <Route exact path="/complete" element={<Complete />}></Route>
                <Route exact path="/disclaimer" element={<Disclaimer />}></Route>
              </Routes>
              <Footer />
            </Router>
          </Layout>
        </Box>
      </Web3ReactProvider>
    );
  }
}
export default App;

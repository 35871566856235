import { Text, VStack, Image, Heading, HStack, Box } from "@chakra-ui/react"
import React from "react"

export default function Disclaimer() {
  return (
    <Box pt={[4,8,16]}>
      <VStack p={[2,4,8]} bg="blackAlpha.100" borderRadius={[0,4,8]} w="full" alignItems="flex-start">
        <Heading as={HStack} fontSize="lg">
          <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
          <Text as="span">
            Disclaimer
          </Text>
        </Heading>
        <Text>
          You are sacrificing to prove how strongly you believe in the right of
          human assembly. You must have no expectation of profit from the work of
          others. The set of people who have sacrificed to show their commitment
          to this political statement makes a great set of people to airdrop free
          things to. These Sacrifice Points are not meant to have any monetary
          value. Remember, you're not buying anything, the world is just noticing
          you are amongst a group of people that sacrificed to make a political
          statement. Some countries tax their citizens when they receive things of
          value. FIRE is designed to start with no value, which is ideal. Consult
          your own legal and financial professionals, as nothing written here
          should be considered professional advice. The only thing we know of set
          to be airdropped for free to this political group so far is FireChain
          (FIRE.) If we hear about other cool things, we'll let you know
        </Text>
      </VStack>
    </Box>
  )
}

import React from "react"
import { Helmet } from "react-helmet"

export default function SolutionsAgb() {
  return (
    <>
      <Helmet>
        <title>7bp Solutions - Benjamin Philipp</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="/bp.la/style.solutions.css" />     
      </Helmet>
      <main className="agb">
        <h1>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h1>
        § 1 Geltungsbereich und Anbieter<br />
        (1) Die Allgemeinen Geschäftsbedingungen (nachfolgend „AGB" genannt) regeln den Verkauf von Produkten durch Benjamin Philipp (7bp Solutions) (nachfolgend Anbieter) an Sie, in ihrer zum Zeitpunkt der Bestellung gültigen Fassung.<br />
        (2) Abweichende AGB des Bestellers werden zurückgewiesen.<br />
        (3) Bitte lesen Sie diese Bedingungen aufmerksam, bevor Sie eine Bestellung an Benjamin Philipp (7bp Solutions) aufgeben. Durch Aufgabe einer Bestellung an Benjamin Philipp (7bp Solutions) erklären Sie sich mit der Anwendung dieser Verkaufsbedingungen auf Ihre Bestellung einverstanden.<br />
        (4) Auf ebay bieten wir Ihnen den Verkauf von folgenden Produkten an:<br />
        Individuelle und markenlose Produkte, die durch den Inhaber des Einzelnunternehmens erzeugt bzw. konfiguriert werden.<br />
        § 2 Zustandekommen des Vertrages<br />
        (1) Verträge auf diesem Portal können ausschließlich in deutscher Sprache abgeschlossen werden.<br />
        (2) Die Angebote richten sich ausschließlich an Endkunden mit einer Rechnungs- und Lieferanschrift in:<br />
        weltweit.<br />
        Bei einzelnen Sperrgutartikeln können die möglichen Lieferadressen sowie der Ablieferungsort beschränkt sein; die Beschränkung ist im jeweiligen Listenpreis ausgewiesen.<br />
        (3) Der Besteller muss das 18. Lebensjahr vollendet haben.<br />
        (4) Die Präsentation der Waren im Online-Shop stellt kein rechtlich wirksames Angebot dar. Durch die Präsentation der Ware wird der Kunde lediglich dazu aufgefordert ein Angebot zu machen.<br />
        (5) Ihre Bestellung stellt ein Angebot an ebay zum Abschluss eines Kaufvertrages dar. Der Besteller gibt ein verbindliches Angebot ab, wenn er den Online- Bestellprozess unter Eingabe der dort verlangten Angaben durchlaufen hat und im letzten Bestellschritt den Button "zahlungspflichtig bestellen" anklickt.<br />
        (6) Der Kaufvertrag zwischen dem Anbieter und dem Besteller kommt erst durch eine Annahmeerklärung des Anbieters zustande. Diese erfolgt zum früheren der beiden Termine, entweder Zusendung der Ware oder Zusendung einer Versandbestätigung per E-Mail. Beachten Sie, dass die Bestätigung über den Eingang Ihrer Bestellung keine Annahmeerklärung im zuvor genannten Sinne darstellt.<br />
        (7) Die Wirksamkeit von Verträgen über größere als haushaltsübliche Mengen sowie der gewerbliche Weiterverkauf des Kaufgegenstands bedarf der ausdrücklichen Bestätigung seitens des Anbieters. Dies bezieht sich sowohl auf die Anzahl der bestellten Produkte im Rahmen einer Bestellung als auch auf die Aufgabe mehrerer Bestellungen desselben Produkts, bei denen die einzelnen Bestellungen eine haushaltsübliche Menge umfassen.<br />
        (8) Ihre Bestellungen werden bei uns nach Vertragsschluss gespeichert. Sollten Sie Ihre Unterlagen zu Ihren Bestellungen verlieren, wenden Sie sich bitte per E-Mail oder Telefon an uns. Wir senden Ihnen eine Kopie der Bestelldaten zu.<br />
        (9) Sie stimmen zu, dass Sie Rechnungen elektronisch erhalten. Elektronische Rechnungen werden Ihnen per E-Mail oder in dem Kundenkonto der Webseite zur Verfügung gestellt. Wir werden Sie für jede Lieferung in der Versandbestätigung darüber informieren, ob eine elektronische Rechnung verfügbar ist. Weitere Informationen über elektronische Rechnungen erhalten Sie auf unserer Website.<br />
        § 3 Preise und Versandkosten<br />
        (1) Unsere Preise beinhalten die jeweils gültige gesetzliche Mehrwertsteuer und verstehen sich ohne Versandkostenpauschale oder Versandkostenzuschlag. Die Versandkostenzuschläge variieren je nach Lieferart und Artikelbeschaffenheit.<br />
        (2) Trotz unserer größten Bemühungen kann eine kleine Anzahl der Produkte in unserem Katalog mit dem falschen Preis ausgezeichnet sein. Wir überprüfen die Preise, wenn wir Ihre Bestellung bearbeiten und bevor wir die Zahlung belasten. Wenn ein Produkt mit einem falschen Preis ausgezeichnet ist und der korrekte Preis höher ist, als der Preis auf der Webseite, werden wir Sie vor Versand der Ware kontaktieren, um Sie zu fragen, ob Sie das Produkt zum korrekten Preis kaufen oder die Bestellung stornieren möchten. Sollte der korrekte Preis eines Produkts niedriger sein, als der von uns angegebene Preis, werden wir den niedrigeren Betrag berechnen und Ihnen das Produkt zusenden.<br />
        (3) Es gelten die Preise zum Zeitpunkt der Bestellung. Falls Listenpreise vorhanden sein sollten, gelten die Preise des zum Zeitpunkt der Bestellung gültigen Listenpreises.<br />
        § 4 Lieferung und Stornierung<br />
        (1) Sofern nicht anders vereinbart, erfolgt die Lieferung an die vom Besteller angegebene Lieferadresse. Auf der Webseite finden Sie Hinweise zur Verfügbarkeit von Produkten, die von ebay verkauft werden (z.B. auf der jeweiligen Produktdetailseite). Wir weisen darauf hin, dass sämtliche Angaben zu Verfügbarkeit, Versand oder Zustellung eines Produktes lediglich voraussichtliche Angaben und ungefähre Richtwerte sind. Sie stellen keine verbindlichen bzw. garantierten Versand- oder Liefertermine dar, außer wenn dies bei den Versandoptionen des jeweiligen Produktes ausdrücklich als verbindlicher Termin bezeichnet ist.<br />
        (2) Sofern ebay während der Bearbeitung Ihrer Bestellung feststellt, dass von Ihnen bestellte Produkte nicht verfügbar sind, werden Sie darüber gesondert per E-Mail oder per Nachricht in Ihrem Kundenkonto informiert. Die gesetzlichen Ansprüche des Bestellers bleiben unberührt.<br />
        (3) Soweit eine Lieferung an den Besteller nicht möglich ist, weil die gelieferte Ware nicht durch die Eingangstür, Haustür oder den Treppenaufgang des Bestellers passt oder weil der Besteller nicht unter der von ihm angegebenen Lieferadresse angetroffen wird, obwohl der Lieferzeitpunkt dem Besteller mit angemessener Frist angekündigt wurde, trägt der Besteller die Kosten für die erfolglose Anlieferung.<br />
        (4) Die Lieferung erfolgt je nach Zahlungsart des Kunden. Bei Vorkasse erfolgt die Lieferung nach der Erteilung des Zahlungsauftrags an das überweisende Kreditinstitut. Bei Zahlung per Paypal, Kreditkarte, Geschenkkarte, Lastschrift, Sofortüberweisung oder Rechnung erfolgt die Lieferung nach Vertragsschluss.<br />
        (5) Wenn Ihre Bestellung in mehr als einem Paket versendet wird, kann es vorkommen, dass Sie für jedes Paket eine eigene Versandbestätigung erhalten. In diesem Fall kommt bezüglich jeder Versandbestätigung ein separater Kaufvertrag zwischen uns über die in der jeweiligen Versandbestätigung aufgeführten Produkte zustande. Vertragspartner ist Benjamin Philipp (7bp Solutions). Ungeachtet Ihres Widerrufsrechts können Sie Ihre Bestellung für ein Produkt vor Zusendung der zugehörigen Versandbestätigung jederzeit kostenfrei stornieren.<br />
        § 5 Zoll<br />
        (1) Wenn Sie Produkte bei ebay zur Lieferung außerhalb der Europäischen Union bestellen, können Sie Importzöllen und -steuern unterliegen, die erhoben werden, sobald das Paket den bestimmten Zielort erreicht. Jegliche zusätzlichen Gebühren für die Zollabfertigung müssen von Ihnen getragen werden; wir haben keinen Einfluss auf diese Gebühren. Zollregelungen unterscheiden sich stark von Land zu Land, so dass Sie Ihre örtliche Zollbehörde für nähere Informationen kontaktieren sollten.<br />
        (2) Ferner beachten Sie bitte, dass Sie bei Bestellungen bei ebay als Einführender angesehen werden und alle Gesetze und Verordnungen des Landes, in dem Sie die Produkte erhalten, einhalten müssen. Der Schutz Ihrer Daten ist wichtig für uns und wir möchten unsere internationalen Kunden darauf aufmerksam machen, dass grenzüberschreitende Lieferungen der Öffnung und Untersuchung durch Zollbehörden unterliegen. Für weitere Informationen lesen Sie bitte unsere Zollinformationen.<br />
        § 6 Zahlung<br />
        (1) Der Besteller kann die Ware durch folgende Zahlungsarten bezahlen: - Paypal<br />
        - Kreditkarte<br />
        - Vorkasse<br />
        (2) Bestimmte Zahlungsarten können im Einzelfall von dem Anbieter ausgeschlossen werden.<br />
        (3) Dem Besteller ist nicht gestattet die Ware durch das Senden von Bargeld oder Schecks zu bezahlen.<br />
        (4) Sollte der Besteller ein Online-Zahlungsverfahren wählen, ermächtigt der Besteller den Anbieter dadurch, die fälligen Beträge zum Zeitpunkt der Bestellung einzuziehen.<br />
        (5) Sollte der Anbieter die Bezahlung per Vorkasse anbieten und der Besteller diese Zahlungsart wählen, hat der Besteller den Rechnungsbetrag innerhalb von fünf Kalendertagen nach Eingang der Bestellung, auf das Konto des Anbieters zu überweisen. Der Anbieter reserviert die Ware dementsprechend für fünf Kalendertage.<br />
        (6) Sollte der Anbieter die Bezahlung per Kreditkarte anbieten und der Besteller diese Zahlungsart wählen, ermächtigt dieser den Anbieter ausdrücklich dazu, die fälligen Beträge nach Versand der Teillieferungen oder Warenlieferungen einzuziehen.<br />
        <br />
        (7) Sollte der Anbieter die Bezahlung per Lastschrift anbieten und der Besteller diese Zahlungsart wählen, erteilt der Besteller dem Anbieter ein SEPA Basismandat. Sollte es bei der Zahlung per Lastschrift zu einer Rückbuchung einer Zahlungstransaktion mangels Kontodeckung oder aufgrund falsch übermittelter Daten der Bankverbindung kommen, so hat der Besteller dafür die Kosten zu tragen.<br />
        (8) Sollte der Anbieter die Bezahlung per Vorkasse anbieten und der Besteller diese Zahlungsart wählen, verpflichtet sich der Besteller, den Rechnungsbetrag innerhalb von 14 Tagen nach Warenversand, ohne jeglichen Abzug von Skonto zu begleichen.<br />
        (9) Sollte der Besteller mit der Zahlung in Verzug kommen, so behält sich der Anbieter die Geltendmachung des Verzugschadens vor.<br />
        § 7 Aufrechnung und Zurückbehaltungsrecht<br />
        (1) Dem Besteller steht das Recht zur Aufrechnung nur zu, wenn die Gegenforderung des Bestellers rechtskräftig festgestellt worden ist oder von dem Anbieter nicht bestritten wurde.<br />
        (2) Der Besteller kann ein Zurückbehaltungsrecht nur ausüben, soweit Ihre Gegenforderung auf demselben Vertragsverhältnis beruht.<br />
        § 8 Eigentumsvorbehalt<br />
        Benjamin Philipp (7bp Solutions) behält sich das Eigentum an der Ware bis zur vollständigen Bezahlung vor.<br />
        § 9 Transportschäden<br />
        (1) Sollte der Besteller die Ware mit offensichtlichen Transportschäden erhalten, so fordert der Anbieter ihn dazu auf diese schnellstmöglich zu reklamieren.<br />
        (2) Sollte der Besteller die Reklamation versäumen, hat dies keine Konsequenzen auf die gesetzlichen Gewährleistungsrechte. Die Reklamation bezweckt, dass der Anbieter seine eigenen Ansprüche gegenüber dem Frachtführer geltend machen kann.<br />
        § 10 Mängelrecht<br />
        (1) Ist der Besteller ein Verbraucher, so richtet sich die Gewährleistung und Mängelhaftung des gelieferten Kaufgegenstandes nach den gesetzlichen<br />
        <br />
        Vorschriften: Demnach haben Besteller in der Europäischen Union zusätzlich zu Ihrer 30-Tage Rückgabegarantie für einen Zeitraum von zwei Jahren ab der Lieferung der Ware Gewährleistungsrechte und können die Reparatur oder den Ersatz der auf ebay gekauften Produkte verlangen, wenn diese sich als mangelhaft oder nicht wie beschrieben erweisen. Wenn die Ware nicht innerhalb einer angemessenen Zeit oder nicht ohne Schwierigkeiten repariert oder ersetzt werden kann, können Sie die Rückerstattung oder Minderung des Kaufpreises verlangen.<br />
        (2) Im Fall von gebrauchten Waren kann die Gewährleistungsfrist kürzer als zwei Jahre sein.<br />
        (3) Ist der Besteller kein Verbraucher, so wird der Mangel durch Neulieferung oder Neuerfüllung beseitigt.<br />
        (4) Ist der Besteller kein Verbraucher so beträgt die Verjährungsfrist ein Jahr. Dies gilt soweit keine Schadens- und Aufwendungsersatzansprüche, die sich auf Ersatz eines Schadens in Bezug auf Körper und Gesundheit oder auf Vorsatz oder grobe Fahrlässigkeit geltend gemacht werden.<br />
        § 11 Haftungsbegrenzung (Produkte)<br />
        (1) Der Anbieter haftet für Schadensersatzansprüche des Bestellers aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten, sowie für sonstige Schäden, die auf ihrer vorsätzlichen oder grob fahrlässigen Pflichtverletzung, oder einer der gesetzlichen Vertreter oder Erfüllungsgehilfen des Anbieters beruhen.<br />
        (2) Als wesentliche Vertragspflichten, gelten Pflichten deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist.<br />
        (3) Der Anbieter haftet für Verletzungen wesentlicher Vertragspflichten, die auf vertragstypischen, vorhersehbaren Schäden beruhen, vorausgesetzt der Schaden wurde einfach fahrlässig verursacht. Diese Begrenzung gilt nicht für Schadensersatzansprüche des Bestellers, die auf einer Verletzung des Lebens, des Körpers oder der Gesundheit beruhen.<br />
        (4) Die Vorschriften des Produkthaftungsgesetzes bleiben unberührt.<br />
        (5) Soweit die Haftung von ebay ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen.<br />
        § 12 Widerrufsbelehrung<br />
        <br />
        (1) Ist der Besteller ein Verbraucher, so hat er ein Widerrufsrecht nach Maßgabe der folgenden Bestimmungen:<br />
        (2) Widerrufsrecht<br />
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br />
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Ware (oder die letzte Ware, Teilsendung oder Stück im Falle eines Vertrags über mehrere Waren einer einheitlichen Bestellung oder die Lieferung einer Ware in mehreren Teilsendungen oder Stücken) in Besitz genommen haben, ohne Angabe von Gründen zu widerrufen.<br />
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns: Benjamin Philipp (7bp Solutions)<br />
        Ahornstr. 8 50169 Kerpen<br />
        Telefon: +4922376035600<br />
        E-Mail: solutions@7bp.de<br />
        mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das Muster-Widerrufsformular auf unserer Internetseite verwenden oder uns eine andere eindeutige Erklärung übermitteln. Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (z.B. per E-Mail) eine Bestätigung über den Eingang eines solchen Widerrufs übermitteln.<br />
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden und Sie die Waren über unser Online-Rücksendezentrum innerhalb der unten definierten Frist zurückgesendet haben.<br />
        (3) Folgen des Widerrufs<br />
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen<br />
        <br />
        Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.<br />
        Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.<br />
        Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen 14 Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an<br />
        Benjamin Philipp (7bp Solutions)<br />
        Ahornstr. 8 50169 Kerpen<br />
        Telefon: +4922376035600 E-Mail: solutions@7bp.de<br />
        zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von 14 Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.<br />
        (4) Ausnahmen vom Widerrufsrecht<br />
        Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.<br />
        Das Widerrufsrecht besteht nicht bzw. erlischt bei folgenden Verträgen:<br />
        zur Lieferung von Waren, die aus Gründen des Gesundheitsschutzes oder aus Hygienegründen nicht zur Rückgabe geeignet sind und deren Versiegelung nach der Lieferung entfernt wurde oder die nach der Lieferung aufgrund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden;<br />
        zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der Lieferung entfernt wurde;<br />
        zur Lieferung von Waren, die nach Kundenspezifikation angefertigt werden oder eindeutig auf die persönlichen Bedürfnisse zugeschnitten sind<br />
        zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde;<br />
        <br />
        bei Dienstleistungen, wenn ebay diese vollständig erbracht hat und Sie vor der Bestellung zur Kenntnis genommen und ausdrücklich zugestimmt haben, dass wir mit der Erbringung der Dienstleistung beginnen können und Sie Ihr Widerrufsrecht bei vollständiger Vertragserfüllung verlieren;<br />
        zur Lieferung von Zeitungen, Zeitschriften oder Illustrierte, mit Ausnahme von Abonnement-Verträgen; und<br />
        zur Lieferung alkoholischer Getränke, deren Preis beim Abschluss des Kaufvertrags vereinbart wurde, deren Lieferung aber erst nach 30 Tagen erfolgen kann und deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die der Unternehmer keinen Einfluss hat.<br />
        § 13 Ausschluss des Widerrufsrechts<br />
        (1) Das Widerrufsrecht besteht nicht bei Verträgen<br />
        zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind;<br />
        zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde;<br />
        (2) Das Widerrufsrecht erlischt vorzeitig bei Verträgen<br />
        zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde;<br />
        zur Lieferung von Waren, wenn diese nach der Lieferung auf Grund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden;<br />
        zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelter Packung, wenn die Versiegelung nach der Lieferung entfernt wurde.<br />
        § 14 Datenschutz<br />
        (1) Sollten personenbezogene Daten (z.B. Name, Anschrift, E-Mail-Adresse) erhoben werden, verpflichten wir uns dazu, Ihre vorherige Einverständnis einzuholen. Wir verpflichten uns dazu, keine Daten an Dritte weiterzugeben, es sei denn, Sie haben zuvor eingewilligt.<br />
        <br />
        (2) Wir weisen darauf hin, dass die Übertragung von Daten im Internet (z. B. per E- Mail) Sicherheitslücken aufweisen kann. Demnach kann ein fehlerfreier und störungsfreier Schutz der Daten Dritter nicht vollständig gewährleistet werden. Diesbezüglich ist unsere Haftung ausgeschlossen.<br />
        (3) Dritte sind nicht dazu berechtigt, Kontaktdaten für gewerbliche Aktivitäten zu nutzen, sofern der Anbieter den betroffenen Personen vorher eine schriftliche Einwilligung erteilt hat.<br />
        (4) Sie haben jederzeit das Recht, von ebay über den Sie betreffenden Datenbestand vollständig und unentgeltlich Auskunft zu erhalten.<br />
        (5) Des Weiteren besteht ein Recht auf Berichtigung/Löschung von Daten/Einschränkung der Verarbeitung für den Nutzer.<br />
        § 15 Cookies<br />
        (1) Zur Anzeige des Produktangebotes kann es vorkommen, dass wir Cookies einsetzen. Bei Cookies handelt es sich um kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden.<br />
        (2) Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.<br />
        (3) Durch den Einsatz von Cookies kann den Nutzern dieser Internetseite nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.<br />
        (4) Wir weisen Sie darauf hin, dass einige dieser Cookies von unserem Server auf Ihr Computersystem überspielt werden, wobei es sich dabei meist um so genannte sitzungsbezogene Cookies handelt. Sitzungsbezogene Cookies zeichnen sich dadurch aus, dass diese automatisch nach Ende der Browser-Sitzung wieder von Ihrer Festplatte gelöscht werden. Andere Cookies verbleiben auf Ihrem Computersystem und ermöglichen es uns, Ihr Computersystem bei Ihrem nächsten Besuch wieder zu erkennen (sog. dauerhafte Cookies).<br />
        (5) Sie können der Speicherung von Cookies widersprechen, hierzu steht Ihnen ein Banner zu Verfügung dem Sie widersprechen/annehmen können.<br />
        <br />
        (6) Selbstverständlich können Sie Ihren Browser so einstellen, dass keine Cookies auf der Festplatte abgelegt werden bzw. bereits abgelegte Cookies wieder gelöscht werden. Die Anweisungen bezüglich der Verhinderung sowie Löschung von Cookies können Sie der Hilfefunktion Ihres Browsers oder Softwareherstellers entnehmen.<br />
        § 16 Gerichtsstand und anwendbares Recht<br />
        (1) Für Meinungsverschiedenheiten und Streitigkeiten anlässlich dieses Vertrages gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN- Kaufrechts.<br />
        (2) Alleiniger Gerichtsstand bei Bestellungen von Kaufleuten, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen ist der Sitz des Anbieters.<br />
        § 17 Schlussbestimmungen<br />
        (1) Vertragssprache ist deutsch.<br />
        (2) Wir bieten keine Produkte oder Dienstleistungen zum Kauf durch Minderjährige an. Unsere Produkte für Kinder können nur von Erwachsenen gekauft werden. Falls Sie unter 18 sind, dürfen Sie ebay nur unter Mitwirkung eines Elternteils oder Erziehungsberechtigten nutzen.<br />
        (3) Wenn Sie diese AGB verletzen und wir unternehmen hiergegen nichts, sind wir weiterhin berechtigt, von unseren Rechten bei jeder anderen Gelegenheit, in der Sie diese Verkaufsbedingungen verletzen, Gebrauch zu machen.<br />
        (4) Wir behalten uns das Recht vor, Änderungen an unserer Webseite, Regelwerken, Bedingungen einschließlich dieser AGB jederzeit vorzunehmen. Auf Ihre Bestellung finden jeweils die Verkaufsbedingungen, Vertragsbedingungen und AGB Anwendung, die zu dem Zeitpunkt Ihrer Bestellung in Kraft sind, es sei denn eine Änderung an diesen Bedingungen ist gesetzlich oder auf behördliche Anordnung erforderlich (in diesem Fall finden sie auch auf Bestellungen Anwendung, die Sie zuvor getätigt haben). Falls eine Regelung in diesen Verkaufsbedingungen unwirksam, nichtig oder aus irgendeinem Grund undurchsetzbar ist, gilt diese Regelung als trennbar und beeinflusst die Gültigkeit und Durchsetzbarkeit der verbleibenden Regelungen nicht.<br />
        (5) Die Unwirksamkeit einer Bestimmung berührt die Wirksamkeit der anderen Bestimmungen aus dem Vertrag nicht. Sollte dieser Fall eintreten, soll die Bestimmung nach Sinn und Zweck durch eine andere rechtlich zulässige Bestimmung ersetzt werden, die dem Sinn und Zweck der unwirksamen Bestimmung entspricht.
      </main>
    </>
  )
}
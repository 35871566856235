import React from "react"
import HelpOut from "./tabs/HelpOut"
import LogoDownloads from "./tabs/LogoDownloads"
import StyleNSpelling from "./tabs/StyleNSpelling"
import { VStack } from "@chakra-ui/react"
import Meta from "../../../_shared/firechain/Meta"

export default function Branding() {
  return (
    <>
      <Meta title="Branding">
        The official branding guide of Firechain
      </Meta>
      
      <VStack w="full" alignItems="flex-start">
        <HelpOut />
        <LogoDownloads />
        <StyleNSpelling />
      </VStack>
    </>
  )
}

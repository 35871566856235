import React, { useEffect, useState } from "react"
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Center,
  SimpleGrid,
  Input,
} from "@chakra-ui/react"
import theme from "../../../theme"


export default function ColorPicker({ color: color_, onChange }) {
  const getColorValue = (colorKey) => {
    const [colorName, shade] = colorKey.split(".")
    return theme.colors[colorName]?.[shade] || colorKey
  }
  
  const [color, setColor] = useState(color_)

  const colors = [
    "white",
    "black",
    "gray.500",
    "red.500",
    "green.500",
    "blue.500",
    "yellow.500",
    "orange.500",
    "purple.500",
    "pink.500"
  ]

  useEffect(() => {
    onChange(getColorValue(color))
  }, [color, onChange])

  return (
    <Popover variant="picker">
      <PopoverTrigger>
        <Button
          aria-label={color}
          background={color}
          height="22px"
          width="22px"
          padding={0}
          border="1px solid #cccccc"
          minWidth="unset"
          borderRadius={3}
        ></Button>
      </PopoverTrigger>
      <PopoverContent width="170px">
        <PopoverArrow bg={color} />
        <PopoverCloseButton color="white" />
        <PopoverHeader
          height="100px"
          backgroundColor={color}
          borderTopLeftRadius={5}
          borderTopRightRadius={5}
          color="white"
        >
          <Center height="100%">{color}</Center>
        </PopoverHeader>
        <PopoverBody height="120px">
          <SimpleGrid columns={5} spacing={2}>
            {colors.map((c) => (
              <Button
                key={c}
                aria-label={c}
                background={c}
                height="22px"
                width="22px"
                padding={0}
                minWidth="unset"
                border="1px solid #cccccc"
                borderRadius={3}
                _hover={{ background: c }}
                onClick={() => {
                  setColor(c)
                }}
              ></Button>
            ))}
          </SimpleGrid>
          <Input
            borderRadius={3}
            marginTop={3}
            placeholder="red.100"
            size="sm"
            value={color}
            onChange={(e) => {
              setColor(e.target.value)
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Link, Wrap, WrapItem } from "@chakra-ui/layout";

const chains = [
  {
    name: "Avalanche",
    icon: "/thefirechain.com/images/logos/avax.png",
    href: "/avalanche",
  },
  {
    name: "BNB Smart Chain",
    icon: "/thefirechain.com/images/logos/bnb.png",
    href: "/bsc",
  },
  {
    name: "Cronos",
    icon: "/thefirechain.com/images/logos/cro.png",
    href: "/cronos",
  },
  {
    name: "Ethereum",
    icon: "/thefirechain.com/images/logos/eth.png",
    href: "/ethereum",
  },
  {
    name: "Fantom",
    icon: "/thefirechain.com/images/logos/ftm.png",
    href: "/fantom",
  },
  {
    name: "Heco",
    icon: "/thefirechain.com/images/logos/ht.png",
    href: "/heco",
  },
  {
    name: "Optimism",
    icon: "/thefirechain.com/images/logos/opeth.svg",
    href: "/optimism",
  },
  {
    name: "Polygon",
    icon: "/thefirechain.com/images/logos/poly.png",
    href: "/matic",
  },
]

export default function StepIcons({ withLinks }) {
  return (
    <Wrap w="full" spacing={6} justify="center">
      {chains.map((it, index) => (
        <WrapItem key={index}>
          <Button as={withLinks ? Link : undefined} href={withLinks ? it.href : undefined} size="lg" variant={withLinks ? "outline" : "link"} _hover={ withLinks ? { bg: "white" } : undefined} colorScheme="black" pointerEvents={!withLinks ? "none" : undefined} leftIcon={<Image w={5} h={5} src={it.icon} />}>{it.name}</Button>
        </WrapItem>
      ))}
    </Wrap>
  );
}
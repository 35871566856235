import React from "react"
import { Box, Text, Table as ChakraTable, HStack, Heading, Image, Tbody, Tr, Td, TableCaption, TableContainer, } from "@chakra-ui/react"

function Table() {
  return (
    <TableContainer>
      <ChakraTable variant="simple" colorScheme="orange">
        <TableCaption>Get Fired up before the ICO ends</TableCaption>
        <Tbody>
          <Tr>
            <Td>Name:</Td>
            <Td isNumeric>Rinia</Td>
          </Tr>
          <Tr>
            <Td>RPC:</Td>
            <Td isNumeric>https://rinia.rpc1.thefirechain.com</Td>
          </Tr>
          <Tr>
            <Td>Symbol:</Td>
            <Td isNumeric>FIRE</Td>
          </Tr>
          <Tr>
            <Td>Chain Id:</Td>
            <Td isNumeric>917</Td>
          </Tr>
          <Tr>
            <Td>Explorer</Td>
            <Td isNumeric>https://rinia.thefirescan.com</Td>
          </Tr>
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default function AddRinia() {
  return (
    <Box borderWidth="1px" borderColor="blackAlpha.500" boxShadow="md" bgImage="linear-gradient(45deg, #ecd35022 0%, rgba(255,255,255,0) 70%)" borderRadius={[0,4,8]} p={[2,4,8]}>
      <Heading>Add Firechain Network</Heading>
      <Heading pb={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          How to add Firechain Testnet?
        </Text>
      </Heading>
      <Table />
    </Box>
  );
}

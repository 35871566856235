import { Helmet } from "react-helmet"

export default function Meta({ title, children }) {
  return (
    <Helmet>
      <title>{title ? `${title} | ` : ""}Firechain</title>
      <meta name="description" content={children} />
      <link rel="icon" href="/thefirechain.com/favicon.ico" />
      <link rel="apple-touch-icon" href="/thefirechain.com/logo192.png" />
      <link rel="manifest" href="/thefirechain.com/manifest.json" />
    </Helmet>
  )
}
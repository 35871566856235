import React from "react"

import { HStack, Box, Heading, Image, Text } from "@chakra-ui/react"

export default function FireNetwork() {
  return (
    <Box p={[2,4,8]}>
      <Heading pb={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          What is FIRE?
        </Text>
      </Heading>
      <Text fontSize="3xl" fontWeight="bold">$FIRE is the native token on the Firechain Network</Text>
    </Box>
  )
}

import React from "react"

import IcoRate from "./tabs/IcoRate"
import ProjectStatus from "./tabs/ProjectStatus"
import VolumeBonus from "./tabs/SacInfo"
import FireNetwork from "./tabs/FireNetwork"
import AddRinia from "./tabs/AddRinia"
import { Stack, VStack, Wrap, WrapItem } from "@chakra-ui/react"
import Meta from "../../../_shared/firechain/Meta"

export default function Main() {
  return (
    <>
      <Meta>
        The official website of the Firechain
      </Meta>
    
      <VStack gap={[4,8,16]} maxW="7xl">
        <Stack direction={["column","column","row"]} w="full" justify="space-between">
          <AddRinia />
          <Wrap>
            <WrapItem>
              <FireNetwork />
            </WrapItem>
            <WrapItem>
              <ProjectStatus />
            </WrapItem>
          </Wrap>
        </Stack>
        <IcoRate />
        <VolumeBonus />
      </VStack>
    </>
  )
}

import React from "react"
import General from "./tabs/General"
import { VStack } from "@chakra-ui/react"
import Meta from "../../../_shared/firechain/Meta"

export default function Faq() {
  return (
    <>
      <Meta title="Frequently Asked Questions">
        Do you have a question about Firechain? Find your answers here.
      </Meta>
      
      <VStack gap={2}>
        <General />
      </VStack>
    </>
  )
}

import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Heading,
  Box,
} from "@chakra-ui/react";

export default function IcoRate() {
  return (
    <Box borderWidth="1px" borderColor="blackAlpha.500" boxShadow="md" bgImage="linear-gradient(45deg, #50d3ec22 0%, rgba(255,255,255,0) 70%)" borderRadius={[0,4,8]} p={[2,4,8]}>
      <Heading pb={[2,4,8]}>ICO RATE FOR FIRECHAIN</Heading>
      <Image src="/thefirechain.com/images/firechain-ico-graph.png" alt="graph" />
      <TableContainer mt={[2,4,8]}>
        <Table variant="simple" colorScheme="orange">
          <Thead>
            <Tr>
              <Th>Price</Th>
              <Th>Till</Th>
              <Th isNumeric>Symbol</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>$0.10</Td>
              <Td>10,500,000</Td>
              <Td isNumeric>FIRE</Td>
            </Tr>
            <Tr>
              <Td>$0.12</Td>
              <Td>12,600,000</Td>
              <Td isNumeric>FIRE</Td>
            </Tr>
            <Tr>
              <Td>$0.14</Td>
              <Td>14,700,000</Td>
              <Td isNumeric>FIRE</Td>
            </Tr>
            <Tr>
              <Td>$0.16</Td>
              <Td>16,800,000</Td>
              <Td isNumeric>FIRE</Td>
            </Tr>
            <Tr>
              <Td>$0.18</Td>
              <Td>18,900,000</Td>
              <Td isNumeric>FIRE</Td>
            </Tr>
            <Tr>
              <Td>$0.20</Td>
              <Td>21,000,000</Td>
              <Td isNumeric>FIRE</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

import React, { Component } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Main from "./pages/main"
import GetFire from "./pages/getFire"
import Faq from "./pages/faq"
import Branding from "./pages/branding"
import More from "./pages/more"
import Disclaimer from "../_shared/firechain/pages/Disclaimer"
import Layout from "../_shared/firechain/Layout"
import Announcement from "./comp/TopAlert"
import { Box } from "@chakra-ui/react"
import Footer from "../_shared/firechain/comp/Footer"

const NAV_ITEMS = [
  {
    label: 'Get FIRE',
    href: '/get-fire',
  },
  {
    label: 'FAQ',
    href: '/faq',
  },
  {
    label: 'Branding',
    href: '/branding',
  },
  {
    label: 'More',
    href: '/more',
  },
];

class App extends Component {
  render() {
    return (
      <Box minW="100%" minH="100vh" bg="white" color="black">
        <Layout announcement={<Announcement />} navItems={NAV_ITEMS}>
          <Router>
            <Routes>
              <Route exact path="/" element={<Main />}></Route>
              <Route exact path="/get-fire" element={<GetFire />}></Route>
              <Route exact path="/faq" element={<Faq />}></Route>
              <Route exact path="/branding" element={<Branding />}></Route>
              <Route exact path="/more" element={<More />}></Route>
              <Route exact path="/disclaimer" element={<Disclaimer />}></Route> 
            </Routes>
          </Router>
          <Footer />
        </Layout>
      </Box>
    )
  }
}
export default App

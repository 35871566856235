import React from "react"
import { Helmet } from "react-helmet"

export default function Solutions() {
  return (
    <>
      <Helmet>
        <title>7bp Solutions - Benjamin Philipp</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="/bp.la/style.solutions.css" />     
      </Helmet>
      <main>
        <img src="/bp.la/7bpsolutions.png" />
        <p>
          Benjamin Philipp<br />
          7bp Solutions<br />
          <br />
          Ahornstr. 8<br />
          50169 Kerpen<br />
          Deutschland<br />
          <br />
          Email: solutions@7bp.de<br />
          Phone: 02237-6035600<br />
          <br />
          USt-ID-Nr.: <em>Gem. § 19 UStG kein MwSt-Ausweis, da Kleinunternehmer</em><br />
          <a href="/solutions/agb">AGB</a>
        </p>
      </main>
    </>
  )
}
import { Box, Button, Stack, Flex, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function LogoDownloads() {
  return (
    <VStack w="full" alignItems="flex-start">
      <Heading as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Logo Downloads
        </Text>
      </Heading>

      <Stack direction={["column","row"]} gap={[2,4,8]} alignItems="center">
        <Box maxW={["100%","40%"]} borderRadius={[2,4,8]} borderWidth="2px" borderColor="blackAlpha.200"><Image src="/thefirechain.com/images/firechain-icon.png" /></Box>
        <Flex gap={2}>
          <Button as="a" href="/thefirechain.com/images/firechain-icon.png" target="_blank">.png</Button>
          <Button isDisabled as="a" href="/thefirechain.com/images/firechain-icon.svg" target="_blank">.svg</Button>
        </Flex>
      </Stack>

      <Stack direction={["column","row"]} gap={[2,4,8]} alignItems="center">
        <Box maxW={["100%","40%"]} borderWidth="2px" borderColor="blackAlpha.200"><Image src="/thefirechain.com/images/rinia-testnet.png" /></Box>
        <Flex gap={2}>
          <Button as="a" href="/thefirechain.com/images/rinia-testnet.png" target="_blank">.png</Button>
          <Button isDisabled as="a" href="/thefirechain.com/images/rinia-testnet.svg" target="_blank">.svg</Button>
        </Flex>
      </Stack>

      <Stack direction={["column","row"]} gap={[2,4,8]} alignItems="center">
        <Box maxW={["100%","40%"]} borderWidth="2px" borderColor="blackAlpha.200"><Image src="/thefirechain.com/images/logo-wordmark-black.png" /></Box>
        <Flex gap={2}>
          <Button as="a" href="/thefirechain.com/images/logo-wordmark-black.png" target="_blank">.png</Button>
          <Button isDisabled as="a" href="/thefirechain.com/images/logo-wordmark-black.svg" target="_blank">.svg</Button>
        </Flex>
      </Stack>

      <Stack direction={["column","row"]} gap={[2,4,8]} alignItems="center">
        <Box maxW={["100%","40%"]} borderWidth="2px" borderColor="blackAlpha.200"><Image src="/thefirechain.com/images/logo-wordmark-rinia-black.png" /></Box>
        <Flex gap={2}>
          <Button as="a" href="/thefirechain.com/images/logo-wordmark-rinia-black.png" target="_blank">.png</Button>
          <Button isDisabled as="a" href="/thefirechain.com/images/logo-wordmark-rinia-black.svg" target="_blank">.svg</Button>
        </Flex>
      </Stack>
    </VStack>
  )
}

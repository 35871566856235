import React from "react";
import BlockChainPay from "./index"

const TOKENS = [
  {
    "symbol": "1INCH",
    "address": "0x111111111117dc0aa78b770fa6a738034120c302",
    "name": "1INCH Token"
  },
  {
    "symbol": "AAVE",
    "address": "0xfb6115445bff7b52feb98650c87f44907e58f802",
    "name": "Binance-Peg Aave Token"
  },
  {
    "symbol": "AVAX",
    "address": "0x1ce0c2827e2ef14d5c4f29a091d735a204794041",
    "name": "Binance-Peg Avalanche Token"
  },
  {
    "symbol": "BTCB",
    "address": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    "name": "Binance-Peg Bitcoin"
  },
  {
    "symbol": "BSC-USD",
    "address": "0x55d398326f99059ff775485246999027b3197955",
    "name": "Binance-Peg BSC-USD (Tether)"
  },
  {
    "symbol": "BUSD",
    "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    "name": "Binance-Peg BUSD Token"
  },
  {
    "symbol": "CAKE",
    "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    "name": "Pancake"
  },
  {
    "symbol": "DAI",
    "address": "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
    "name": "Binance-Peg Dai Token"
  },
  {
    "symbol": "ETH",
    "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    "name": "Binance-Peg Ethereum Token"
  },
  {
    "symbol": "FTM",
    "address": "0xad29abb318791d579433d831ed122afeaf29dcfe",
    "name": "Fantom Token"
  },
  {
    "symbol": "LINK",
    "address": "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
    "name": "Binance-Peg ChainLink Token"
  },
  {
    "symbol": "MATIC",
    "address": "0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
    "name": "Matic Token"
  },
  {
    "symbol": "USDC",
    "address": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    "name": "Binance-Peg USD Coin"
  },
  {
    "symbol": "UST",
    "address": "0x23396cf899ca06c4472205fc903bdb4de249d6fc",
    "name": "Wrapped UST Token"
  }
]

export default function BinanceSmartChain() {
  return <BlockChainPay chainId={56} chainName="BNB Smart Chain" allowNative allowTokens={TOKENS} />
}
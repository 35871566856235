import { Heading, Text, VStack, HStack, Image } from "@chakra-ui/react"
import React from "react"

export default function StyleNSpelling() {
  return (
    <VStack pt={[4,8,16]} w="full" alignItems="flex-start">
      <Heading as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Style &amp; Spelling
        </Text>
      </Heading>
      <Text>
        Firechain, TheFirechain.com, FireChain (FIRE) - please mind the
        capitalization. Writing "TheFirechain.com" is superior to "#Firechain"
        or "$FIRE" (for example on Twitter). It is better to direct users to the
        website instead of the hashtag. For any kind of merchandise or
        advertisement always write "TheFirechain.com" instead of "Firechain".
      </Text>
    </VStack>
  )
}

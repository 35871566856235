import React from "react"

import FirechainInfo from "./tabs/FirechainInfo"
import FounderInfo from "./tabs/FounderInfo"
import { VStack } from "@chakra-ui/react"
import Meta from "../../../_shared/firechain/Meta"

export default function More() {
  return (
    <>
      <Meta title="More">
        Find out more about the Firechain and its founder
      </Meta>
    
      <VStack gap={2}>
        <FirechainInfo />
        <FounderInfo />
      </VStack>
    </>
  )
}

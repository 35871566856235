import React from "react";
import BlockChainPay from "./index"

const TOKENS = [
  {
    "name": "Binance-peg BUSD Token",
    "symbol": "BUSD",
    "address": "0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8"
  },
  {
    "name": "Dai Stablecoin",
    "symbol": "DAI",
    "address": "0xf2001b145b43032aaf5ee2884e456ccd805f677d"
  },
  {
    "name": "USD Coin",
    "symbol": "USDC",
    "address": "0xc21223249ca28397b4b6541dffaecc539bff0c59",
    "decimals": 6
  },
  {
    "name": "Tether USD",
    "symbol": "USDT",
    "address": "0x66e428c3f67a68878562e79a0234c1f83c208770",
    "decimals": 6
  },
  {
    "name": "Wrapped BTC",
    "symbol": "WBTC",
    "address": "0x062e66477faf219f25d27dced647bf57c3107d52",
    "decimals": 8
  },
  {
    "name": "Wrapped Ether",
    "symbol": "WETH",
    "address": "0xe44fd7fcb2b1581822d0c862b68222998a0c299a"
  }
]


export default function Cronos() {
  return <BlockChainPay chainId={25} chainName="CRONOS" allowNative allowTokens={TOKENS} />
}
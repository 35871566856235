import React from "react"
import NeedHelp from "./tabs/NeedHelp"
import Disclaimer from "./tabs/Disclaimer"
import { VStack } from "@chakra-ui/react"
import Meta from "../../../_shared/firechain/Meta"

export default function GetFire() {
  return (
    <>
      <Meta title="Get Fire">
        Learn more about how to get Fire!
      </Meta>
    
      <VStack w="full" alignItems="flex-start">
        <NeedHelp />
        <Disclaimer />
      </VStack>
    </>
  )
}

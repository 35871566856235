import React from "react";
import { useNavigate } from "react-router-dom";

import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";

import StepIcons from "../../_shared/firechain/comp/icons/StepIcons";
import MetaMaskCard from "../../_shared/firechain/comp/connector-cards/metamask";
import CoinbaseWalletCard from "../../_shared/firechain/comp/connector-cards/coinbase-wallet-card";
import WalletConnectCard from "../../_shared/firechain/comp/connector-cards/walletconnect-card";
import { Heading, Text, VStack, HStack, Wrap, WrapItem, Link } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { ChevronRightIcon, WarningIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/button";
import Meta from "../../_shared/firechain/Meta";
import Steps from "../comp/steps";


export default function Step1() {
  const navigate = useNavigate();

  return (
    <>
      <Meta title="Sacrifice">
        Sacrifice for the Firechain and become part of a new journey!
      </Meta>    
      <VStack w="full" alignItems="flex-start">
        <VStack gap={[4,8,16]} w="7xl" maxW="100%">
          <Heading as={HStack} fontSize="2xl">
            <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
            <Text as="span">
              Welcome to the Firechain Sacrifice Phase
            </Text>
          </Heading>
          <Text>
            The Firechain Sacrifice is creating a true decentralized ecosystem for and build by the people. Build from zero, with zero.
          </Text>

          <VStack mb={[4,8,16]} borderWidth="1px" bg="blackAlpha.50" borderColor="blackAlpha.500" borderRadius={[0,4,8]} w="full" alignItems="flex-start" gap={[1,2,4,8]}>          
            <Steps index={0} />

            <VStack w="full" p={[2,4,8]} gap={[2,4,8]}>
              
              <Text textAlign="center" fontSize="2xl" fontWeight="bold">
                You can enter the Firechain sacrifice by selecting any of the following networks:
              </Text>
              
              <StepIcons />

            </VStack>

            <VStack p={[2,4,8]} gap={[1,2,4]} w="full" bg="rgba(0,120,0,0.1)">
              <Text fontSize="xl" fontWeight="bold">
                Connect your wallet provider to simplify the way of sending your sacrifice:
              </Text>

              <Wrap>
                <WrapItem>
                  <MetaMaskCard inline onContinue={() => navigate("/step2")} />
                </WrapItem>
                <WrapItem>
                  <CoinbaseWalletCard
                    inline
                    onContinue={() => navigate("/step2")}
                  />
                </WrapItem>
                <WrapItem>
                  <WalletConnectCard
                    inline
                    onContinue={() => navigate("/step2")}
                  />
                </WrapItem>
              </Wrap>
            </VStack>
            
            <VStack p={[2,4,8]} w="full">

              <Text>
                Or you can send directly from your wallet. Only send from a wallet that you own.
              </Text>
              
              <CopyToClipboard
                text="0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4"
                onCopy={() =>
                  alert(
                    "Copied: 0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4"
                  )
                }
              >
                <HStack bg="blackAlpha.100" borderRadius={[2,4,8]} p={[1,2,4]} maxW="100%" overflow="hidden">
                  <FaCopy
                    color="black"
                    className="fas fa-copy fa-copy:before"
                  />
                  <Text textOverflow="ellipsis">0xE2d5bcB5Ee2D2A59b948153A0d70A208ba8639f4</Text>
                </HStack>
              </CopyToClipboard>

              <HStack>
                <WarningIcon w={[8,8,8,4]} h={[8,8,8,4]} />
                <Text>Do not send any crypto to the address from any exchange. Only send from a wallet that you own the keys to!</Text>
              </HStack>

            </VStack>

            <Text p={[2,4,9]} textAlign="center">
              If you don't have a wallet, you can install <Button as={Link} variant="link" href="https://metamask.io" target="_blank" px={1}>MetaMask</Button> - but if you already have a wallet, you can tranfer your exchange funds to your wallet address and start to enter the world to get FIRE.
            </Text>  

            <VStack p={[2,4,8]} w="full" alignItems="flex-end">
              <Button colorScheme="green" size="lg" as={Link} href="/step2" rightIcon={<ChevronRightIcon />}>
                Continue
              </Button>
            </VStack>

          </VStack>
        </VStack>
      </VStack>
    </>
  )
}

import React from "react";
import BlockChainPay from "./index"

const TOKENS = [
  {
    "symbol": "1INCH",
    "address": "0x111111111117dc0aa78b770fa6a738034120c302",
    "name": "1INCH Token",
  },
  {
    "symbol": "AAVE",
    "address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
    "name": "AAVE",
  },
  {
    "symbol": "AMPL",
    "address": "0xd46ba6d942050d489dbd938a2c909a5d5039a161",
    "name": "Ampleforth",
    "decimals": 9,
  },
  {
    "symbol": "AXS",
    "address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
    "name": "Axie Infinity Shard",
  },
  {
    "symbol": "BADGER",
    "address": "0x3472a5a71965499acd81997a54bba8d852c6e53d",
    "name": "BADGER",
  },
  {
    "symbol": "BAT",
    "address": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
    "name": "BAT",
  },
  {
    "symbol": "BNB",
    "address": "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
    "name": "BNB",
  },
  {
    "symbol": "BNT",
    "address": "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
    "name": "Bancor",
  },
  {
    "symbol": "BOND",
    "address": "0x0391d2021f89dc339f60fff84546ea23e337750f",
    "name": "BarnBridge Governance Token",
  },
  {
    "symbol": "BUSD",
    "address": "0x4fabb145d64652a948d72533023f6e7a623c7c53",
    "name": "Binance USD",
  },
  {
    "symbol": "CEL",
    "address": "0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d",
    "name": "Celsius",
    "decimals": 4,
  },
  {
    "symbol": "COMP",
    "address": "0xc00e94cb662c3520282e6f5717214004a7f26888",
    "name": "Compound",
  },
  {
    "symbol": "CRO",
    "address": "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
    "name": "Crypto.com Coin",
    "decimals": 8,
  },
  {
    "symbol": "CRV",
    "address": "0xd533a949740bb3306d119cc777fa900ba034cd52",
    "name": "Curve DAO Token",
  },
  {
    "symbol": "DAI",
    "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
    "name": "DAI",
  },
  {
    "symbol": "TRIBE",
    "address": "0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
    "name": "TRIBE",
  },
  {
    "symbol": "TUSD",
    "address": "0x0000000000085d4780b73119b644ae5ecd22b376",
    "name": "True USD",
  },
  {
    "symbol": "UNI",
    "address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    "name": "Uniswap",
  },
  {
    "symbol": "USDC",
    "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "name": "USD Coin",
    "decimals": 6,
  },
  {
    "symbol": "USDP",
    "address": "0x8e870d67f660d95d5be530380d0ec0bd388289e1",
    "name": "Pax Dollar",
  },
  {
    "symbol": "USDT",
    "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
    "name": "USD Tether",
    "decimals": 6,
  },
  {
    "symbol": "UST",
    "address": "0xa47c8bf37f92abed4a126bda807a7b7498661acd",
    "name": "Wrapped UST Token",
  },
  {
    "symbol": "WBTC",
    "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    "name": "Wrapped Bitcoin",
    "decimals": 8,
  },
  {
    "symbol": "WETH",
    "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    "name": "Wrapped Ether",
  },
  {
    "symbol": "WISE",
    "address": "0x66a0f676479cee1d7373f3dc2e2952778bff5bd6",
    "name": "WiseToken",
  },
  {
    "symbol": "YFI",
    "address": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
    "name": "yearn.finance",
  },
  {
    "symbol": "ZRX",
    "address": "0xe41d2489571d322189246dafa5ebde1f4699f498",
    "name": "0x Protocol Token",
  }
]

export default function Ethereum() {
  return <BlockChainPay chainId={1} chainName="ETHEREUM" allowNative allowTokens={TOKENS} />
}

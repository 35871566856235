import { URI_AVAILABLE } from '@web3-react/walletconnect'
import { useEffect, useState } from 'react'

import { hooks, walletConnect } from '../../lib/connectors/walletConnect'
import { Card } from '../connector-card'

const { useChainId, useAccounts, useIsActivating, useIsActive, useProvider, useENSNames } = hooks

export default function WalletConnectCard({ wide, inline, withSend, desiredChainId, isSendingETH, amount, toAddress, tokenAddress, tokenDecimals, onContinue, onPaid }) {
  const chainId = useChainId()
  const accounts = useAccounts()
  const isActivating = useIsActivating()

  const isActive = useIsActive()

  const provider = useProvider()
  const ENSNames = useENSNames(provider)

  const [error, setError] = useState(undefined)

  // log URI when available
  useEffect(() => {
    walletConnect.events.on(URI_AVAILABLE, (uri) => {
      console.log(`uri: ${uri}`)
    })
  }, [])

  // attempt to connect eagerly on mount
  useEffect(() => {
    walletConnect.connectEagerly().catch(() => {
      console.debug('Failed to connect eagerly to walletconnect')
    })
  }, [])

  return (
    <Card
      wide={wide}
      connector={walletConnect}
      activeChainId={chainId}
      isActivating={isActivating}
      isActive={isActive}
      error={error}
      setError={setError}
      accounts={accounts}
      provider={provider}
      ENSNames={ENSNames}
      desiredChainId={desiredChainId}
      isSendingETH={isSendingETH}
      amount={amount}
      toAddress={toAddress}
      tokenAddress={tokenAddress}
      tokenDecimals={tokenDecimals}
      icon="/thefirechain.com/images/wallet-connect.svg"
      withSend={withSend}
      inline={inline}
      onContinue={onContinue}
      onPaid={onPaid}
    />
  )
}
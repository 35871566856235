import { Button, Heading, Image, VStack, Text, HStack } from "@chakra-ui/react"
import React from "react"

export default function General() {
  return (
    <VStack w="full" alignItems="flex-start">
      <Heading>General Questions</Heading>
      
      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Is this Firechain?
        </Text>
      </Heading>
      <Text>
        Yes.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Will the blockchain have it's own native token?
        </Text>
      </Heading>
      <Text>
        Just like all other blockchain, Firechain will have it's on native token
        called FireChain, symbol: FIRE
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          When will the Firechain be released?
        </Text>
      </Heading>
      <Text>
        When it's ready!
        <Button variant="link" as="a" href="https://www.thefirechain.com" target="_blank" px={1}>Firechain</Button>
        mainnet launches, when the sacrifice is completed (all coins are gone)!
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          How many FIRE will be there? Supply?
        </Text>
      </Heading>
      <Text>
        Unlike the other sacrifice where the supply is determined by the total
        USD value that is sacrificed, the Firechain total supply is limited to
        42,000,000 FIRE.
      </Text>
      <Text>
        All FIRE will be minted to Sacrificers.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          What will FIRE be worth?
        </Text>
      </Heading>
      <Text>
        They will accrue value after you receive them. Even though price
        discovery will happen very fast nobody knows what the price of FIRE
        after the first trade will be.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Can I see the code?
        </Text>
      </Heading>
      <Text>
        Soon. Firechain will be open source.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Can I store my FIRE in MetaMask or FireMask?
        </Text>
      </Heading>
      <Text>
        Yes, you will only need to connect to Firechain by switching the network
        within MetaMask or FireMask. You can store your FIRE in other wallets as
        well.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          I still have questions! Where can I talk about Firechain?
        </Text>
      </Heading>
      <Text>
        Ask your questions to the honorable people of the fire-themed community
        in the official community chat. No admin will ever direct message you!
        Kryptonacci will never ask you for any money.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          What is FIRE?
        </Text>
      </Heading>
      <Text>
        FIRE is the Firechain's native token.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          How many FIRE can I get?
        </Text>
      </Heading>
      <Text>
        Max amount during the sacrifice phase is limited to 21,000,000 tokens at
        a first come, first serve base.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          What happens to the other 21M?
        </Text>
      </Heading>
      <Text>
        10% is allocated for angel investors and 40% is set aside for the team
        which is spread over 5 years. More detailed information can be found in
        the Firechain white paper.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Will FIRE be listed on CEX?
        </Text>
      </Heading>
      <Text>
        Yes, FIRE will get listed on centralized exchanges eventually. FIRE will
        get listed on our own exchange which is called "Fire Station".
      </Text>
      
      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Is Firechain a blockchain?
        </Text>
      </Heading>
      <Text>
        Yes. It's a fork of the Ethereum's PoS consensus.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Will I get rich and go to the moon?
        </Text>
      </Heading>
      <Text>
        Don't have any form of expectations from the work of others.
      </Text>
    </VStack>
  )
}

import React from "react";
import BlockChainPay from "./index"

const TOKENS = [
  {
    "name": "BSC-USD (USDT)",
    "symbol": "Binance-Peg BSC-USD (Tether)",
    "address": "0x55d398326f99059ff775485246999027b3197955"
  },
  {
    "name": "BUSD",
    "symbol": "Binance-Peg BUSD Token",
    "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
  },
  {
    "name": "CAKE",
    "symbol": "Pancake",
    "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82"
  },
  {
    "name": "DAI (BSC)",
    "symbol": "Binance-Peg Dai Token",
    "address": "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3"
  },
  {
    "name": "ETH (BSC)",
    "symbol": "Binance-Peg Ethereum Token",
    "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8"
  },
  {
    "name": "USDC (BSC)",
    "symbol": "Binance-Peg USD Coin",
    "address": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"
  },
  {
    "name": "UST",
    "symbol": "Wrapped UST Token",
    "address": "0x23396cf899ca06c4472205fc903bdb4de249d6fc"
  }
]

export default function Heco() {
  return <BlockChainPay chainId={128} chainName="HECO" allowNative allowTokens={TOKENS} />
}
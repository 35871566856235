import React, { Component } from "react"
import MainPage from "./pages/main-page"
import Solutions from "./pages/solutions"
import SolutionsAgb from "./pages/solutions-agb"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

window.Buffer = window.Buffer || require("buffer").Buffer

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<MainPage />}></Route>
          <Route exact path="/solutions" element={<Solutions />}></Route>
          <Route exact path="/solutions/agb" element={<SolutionsAgb />}></Route>
        </Routes>
      </Router>
    )
  }
}
export default App

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Image, Wrap, HStack, VStack, Text, Heading, WrapItem } from "@chakra-ui/react";
import React from "react";

export default function FirechainInfo() {
  return (
    <VStack w="full" alignItems="flex-start">
      <Heading>Founder Information</Heading>
      
      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
        Firechain was founded by Kryptonacci
        </Text>
      </Heading>
      <Text>
        Kryptstein is the one who develops cool stuff around our Fire-themed
        Ecosystem.
      </Text>
      <Wrap>
        <WrapItem>
          <Button borderRadius="full" pl={1} leftIcon={
            <Image src="/thefirechain.com/images/icons/twitter.png" w={8} h={8} />
          } rightIcon={<ExternalLinkIcon />} as="a" href="https://twitter.com/thefirechain" target="_blank">Twitter</Button>
        </WrapItem>
        <WrapItem>
          <Button borderRadius="full" pl={1} leftIcon={
            <Image src="/thefirechain.com/images/icons/discord.png" w={8} h={8} />
          } rightIcon={<ExternalLinkIcon />} as="a" href="https://discord.com/invite/FVaTXyNnCS" target="_blank">Discord</Button>
        </WrapItem>
        <WrapItem>
          <Button borderRadius="full" pl={1} leftIcon={
            <Image src="/thefirechain.com/images/icons/telegram.png" w={8} h={8} />
          } rightIcon={<ExternalLinkIcon />} as="a" href="https://t.me/thefirechain" target="_blank">Telegram</Button>
        </WrapItem>
        <WrapItem>
          <Button isDisabled borderRadius="full" pl={1} leftIcon={
            <Image src="/thefirechain.com/images/icons/gitlab.png" w={8} h={8} />
          } rightIcon={<ExternalLinkIcon />} as="a" href="https://twitter.com/thefirechain" target="_blank">Gitlab</Button>
        </WrapItem>
      </Wrap>
    </VStack>
  )
}

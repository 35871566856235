import { Button, Image, Text, Heading, HStack, VStack } from "@chakra-ui/react"
import React from "react"

export default function NeedHelp() {
  return (
    <VStack w="full" alignItems="flex-start">
      <Heading>Get Fire!</Heading>
      
      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          I need help to get FIRE!
        </Text>
      </Heading>
      <Text>
        Open and visit
        <Button as="a" variant="link" href="https://info.thefirechain.com" target="_blank" px={1}>info.thefirechain</Button>
        and follow the instructions.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          What can you sacrifice for?
        </Text>
      </Heading>
      <Text>
        By sacrificing your crypto you do NOT buy FIRE. You can give your crypto
        away, sacrifice it as a political statement. Once you have done that you
        do not own it anymore. Never expect profit from the work of others.
        Whoever owns your crypto after you sacrificed it does not work for you.
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          How do I get my FIRE?
        </Text>
      </Heading>
      <Text>
        You'll get your FIRE minted from the zero address to the wallet address
        to you participated with on
        <Button as="a" variant="link" href="https://info.thefirechain.com" target="_blank" pl={1}>info.thefirechain</Button>
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Can I send tokens to the DONA donation addresses?
        </Text>
      </Heading>
      <Text>
        No!
      </Text>

      <Heading pt={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Which coins are supported?
        </Text>
      </Heading>
      <Text>
        A official list of all supported coins/ tokens is available on
        <Button as="a" variant="link" href="https://info.thefirechain.com" target="_blank" pl={1}>info.thefirechain</Button>
      </Text>
    </VStack>
  )
}

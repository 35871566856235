import { FormControl, FormLabel, Box, Button, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Table, Tbody, Td, Th, Tooltip, Tr, VStack, Flex, Switch, Input, InputLeftElement } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ColorPicker from '../comp/color'

const SIZE = 500
const LS_KEY = "fancyshice_social_generator_saved"

const getDataUri = async(e) => new Promise((resolve) => {
  const fileInput = e.target
  if (fileInput.files.length > 0) {
    const selectedFile = fileInput.files[0]
    const reader = new FileReader()

    reader.onload = (event) => {
      const dataURI = event.target.result
      // Now you have the Data URI in the 'dataURI' variable.
      resolve(dataURI)
    }

    // Read the selected file as a Data URI
    reader.readAsDataURL(selectedFile)
  } else {
    resolve(null)
  }
})

export default function SocialGenerator() {
  const canvasRef = useRef(null)
  const tempImgRef = useRef(null)
  const sourceRef = useRef(null)
  const loadingRef = useRef(null)

  const [backgroundImage, setBackgroundImage] = useState(null)
  const [leftIconImage, setLeftIconImage] = useState(null)
  const [rightIconImage, setRightIconImage] = useState(null)
  const [teaserText, setTeaserText] = useState(null)
  const [title, setTitle] = useState(null)

  const [titleColor, setTitleColor] = useState("black")
  const [teaserTextColor, setTeaserTextColor] = useState("black")

  const [scale, setScale] = useState(1)
  const [transparent, setTransparent] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  const [loading, setLoading] = useState(true)

  const resolution = useMemo(() => {
    return `${SIZE * scale}x${SIZE * scale}`
  }, [scale])

  const setPending = (state) => {
    if (state) {
      setLoading(true)
    } else {
      if (loadingRef.current) {
        loadingRef.current.classList.add("done")
      }
      setTimeout(() => {
        setLoading(false)
      }, 100000)
    }
  }

  const update = useCallback(() => {
    setPending(true)

    const trans = transparent ? '' : 'background-color:white;'
    const source = `
      <div xmlns="http://www.w3.org/1999/xhtml" style="display:flex;align-items:center;justify-content:space-evenly;flex-direction:column;width:${SIZE}px;height:${SIZE}px;${trans};background-image:url(${backgroundImage});background-repeat:no-repeat;background-size:cover;">
        <style>
        .shadow {
          border-radius: 50%;
          width: 60px;
          height: 40px;
          background: black;
          opacity: 0.5;
          filter: blur(10px);
          transform: scale(1, 0.2);
        }
        </style>
        <div style="display:flex;flex-direction:column;justify-content:space-evenly;height:90%;width:80%;text-align:center;">
          <div style="color:${teaserTextColor};font-size:24px;font-weight:bold;font-family:sans-serif;text-shadow: 0 0 5px #00000077">
            ${teaserText}
          </div>
          <div style="display:flex;gap:64px;justify-content:space-evenly;">
            <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
              <img style="filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));border-radius:46px;width:92px;height:92px;" src="${leftIconImage}" />
              <div class="shadow"></div>
            </div>
            <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
              <img style="filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));border-radius:46px;width:92px;height:92px;" src="${rightIconImage}" />
              <div class="shadow"></div>
            </div>
          </div>
          <div style="color:${titleColor};font-size:32px;font-weight:bolder;font-family:sans-serif;">
            ${title}
          </div>
        </div>
      </div>`

    sourceRef.current.innerHTML = source

    const canvas = canvasRef.current
    canvas.width = SIZE * scale
    canvas.height = SIZE * scale
    canvas.devicePixelRatio = scale

    tempImgRef.current = new Image()
    tempImgRef.current.onload = () => {
      const ctx = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.drawImage(tempImgRef.current, 0, 0)

      setPending(false)
    }
    tempImgRef.current.src = 'data:image/svg+xml,' + encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" width="${SIZE * scale}" height="${SIZE * scale}">
        <foreignObject 
          style="
            width:${SIZE}px;
            height:${SIZE}px;
            transform:scale(${scale});
          "
        >` + source + `
        </foreignObject>
      </svg>
    `)
  }, [backgroundImage, leftIconImage, rightIconImage, scale, teaserText, teaserTextColor, title, titleColor, transparent])

  useEffect(update, [update])

  useEffect(() => {
    if (!window?.localStorage) {
      return
    }

    const saved = window.localStorage.getItem(LS_KEY)
    console.log(saved)
    try {
      const json = JSON.parse(saved)
      setBackgroundImage(json.background)
      setLeftIconImage(json.left)
      setRightIconImage(json.right)
      setTeaserText(json.text)
      setTitle(json.title)
      setScale(json.scale)
      setTransparent(json.transparent)
      setTitleColor(json.titleColor)
      setTeaserTextColor(json.teaserTextColor)
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    if (!window?.localStorage) {
      return
    }

    const json = {
      background: backgroundImage,
      left: leftIconImage,
      right: rightIconImage,
      text: teaserText,
      title,
      scale,
      transparent,
      titleColor,
      teaserTextColor,
    }
    window.localStorage.setItem(LS_KEY, JSON.stringify(json))
  }, [backgroundImage, leftIconImage, rightIconImage, teaserText, title, scale, transparent, titleColor, teaserTextColor])

  const saveAsPng = () => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.imageSmoothingEnabled = true
    ctx.drawImage(tempImgRef.current, 0, 0)

    const a = document.createElement('a')
    a.href = canvas.toDataURL('image/png')
    a.download = 'image.png'
    a.click()
  }

  const loadBackgroundImage = async(e) => {
    const dataUri = await getDataUri(e)
    setBackgroundImage(dataUri)
  }

  const loadLeftIconImage = async(e) => {
    const dataUri = await getDataUri(e)
    setLeftIconImage(dataUri)
  }

  const loadRightIconImage = async(e) => {
    const dataUri = await getDataUri(e)
    setRightIconImage(dataUri)
  }

  return (
    <Box p={[2,4,8]}>
      <VStack align="flex-start" gap={[2,4,8]} mb={[4,8,16]}>
        <FormControl display='flex' alignItems='center'>
          <FormLabel mb='0'>Keep Transparency</FormLabel>
          <Switch defaultChecked={transparent} onChange={e => setTransparent(e.currentTarget.checked)} />
        </FormControl>
        <FormControl>
          <FormLabel>Scaling Ratio</FormLabel>
          <Slider
            id='slider'
            defaultValue={2}
            step={0.25}
            min={1}
            max={10}
            colorScheme='teal'
            onChange={(v) => setScale(v)}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <SliderMark value={2} mt='1' ml='-2.5' fontSize='sm'>
              2x
            </SliderMark>
            <SliderMark value={5} mt='1' ml='-2.5' fontSize='sm'>
              5x
            </SliderMark>
            <SliderMark value={8} mt='1' ml='-2.5' fontSize='sm'>
              8x
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg='teal.500'
              color='white'
              placement='top'
              isOpen={showTooltip}
              label={`${scale}x`}
            >
              <SliderThumb />
            </Tooltip>
          </Slider>
        </FormControl>
        <FormControl>
          <FormLabel>Background Image</FormLabel>
          <Input type="file" onChange={loadBackgroundImage} />
        </FormControl>
        <FormControl>
          <FormLabel>Left Icon</FormLabel>
          <Input type="file" onChange={loadLeftIconImage} />
        </FormControl>
        <FormControl>
          <FormLabel>Right Icon</FormLabel>
          <Input type="file" onChange={loadRightIconImage} />
        </FormControl>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <Flex align="center" gap={[1,2,4]}> 
            <ColorPicker color={titleColor} onChange={setTitleColor} />
            <Input onChange={e => setTitle(e.currentTarget.value)} defaultValue={title} />
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel>Teaser Text</FormLabel>
          <Flex align="center" gap={[1,2,4]}> 
            <ColorPicker color={teaserTextColor} onChange={setTeaserTextColor} />
            <Input onChange={e => setTeaserText(e.currentTarget.value)} defaultValue={teaserText} />
          </Flex>
        </FormControl>
      
      </VStack>

      <Table border="0" maxW="100%">
        <Tbody>
          <Tr>
            <Th>
              HTML
            </Th>
            <Th>
              <Flex w="full" justify="space-between" align="center">
                <Button size="sm" onClick={saveAsPng}>Save PNG</Button>
                Size: {resolution}
              </Flex>
            </Th>
          </Tr>
          <Tr>
            <Td width="500" valign="top">
              <div ref={sourceRef} style={{ width: `${SIZE}px`, height: `${SIZE}px`, border: "1px solid #cccccc77" }}></div>
            </Td>
            <Td valign="top" pos="relative">
              <Box pointerEvents="none" userSelect="none" transform={`translate(${-SIZE * (scale-1) / 2}px,${-SIZE * (scale-1) / 2}px)`}>
                <Box transform={`scale(${1 / scale})`}>
                  <canvas ref={canvasRef}></canvas>
                </Box>
              </Box>
              {loading && (
                <Box pos="absolute" top={0} left={0} pl={4} pt={4}>
                  <Flex ref={loadingRef} className="loading" pointerEvents="none" bgColor="whiteAlpha.700" userSelect="none" align="center" justify="center" w={`${SIZE}px`} h={`${SIZE}px`}>
                    <Box as="span" className="loading" />
                  </Flex>
                </Box>
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  )
}
import React from "react"

import { HStack, Box, Heading, Image, Text, Wrap, WrapItem, Button } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"

export default function ProjectStatus() {
  return (
    <Box p={[2,4,8]}>
      <Heading pb={[2,4,8]} as={HStack} fontSize="lg">
        <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
        <Text as="span">
          Stay up to date and follow our official accounts:
        </Text>
      </Heading>
      <Wrap>
        <WrapItem>
          <Button variant="outline" rightIcon={<ExternalLinkIcon />} as="a" href="https://t.me/thefirechain" target="_blank">Firechain Telegram</Button>
        </WrapItem>
        <WrapItem>
          <Button variant="outline" rightIcon={<ExternalLinkIcon />} as="a" href="https://t.me/thefirechain" target="_blank">Firechain Telegram</Button>
        </WrapItem>
        <WrapItem>
          <Button variant="outline" rightIcon={<ExternalLinkIcon />} as="a" href="https://t.me/donaswaptg" target="_blank">Donaswap Telegram</Button>
        </WrapItem>
        <WrapItem>
          <Button variant="outline" rightIcon={<ExternalLinkIcon />} as="a" href="https://twitter.com/thefirechain" target="_blank">Firechain Twitter</Button>
        </WrapItem>
        <WrapItem>
          <Button variant="outline" rightIcon={<ExternalLinkIcon />} as="a" href="https://twitter.com/donaswap" target="_blank">Donaswap Twitter</Button>
        </WrapItem>
      </Wrap>
    </Box>
  )
}

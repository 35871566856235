import { Stack, Image, VStack, Link, Box } from "@chakra-ui/react"
import Nav from "./Nav"

export default function Header({ announcement, navItems }) {
  return (
    <VStack w="100%" borderBottomWidth="1px" borderBottomColor="blackAlpha.300" bgImage="linear-gradient(90deg, #ecd350cc 0%, rgba(255,255,255,0) 40%)">
      <Stack px={[1,2,4]} py={[2,0,2]} direction={["column","row"]} alignItems="center" w="100%" justifyContent="space-between">
        <Link as="a" href="/"><Image w={[48,"xx-small","xs"]} src="/thefirechain.com/images/logo-wordmark-black.png" alt="Firechain" /></Link>
        <Box zIndex={1} h={[8,16,"auto"]} pt={[0,4,0]}>
          <Nav navItems={navItems} />
        </Box>
      </Stack>
      {announcement}
    </VStack>
  )
}
import { useCallback, useState } from 'react'
import { getName } from '../utils'
import { Status } from './status'
import { getAddChainParameters } from '../lib/connectors/chains'
import { WalletConnect } from '@web3-react/walletconnect'
import { ethers } from 'ethers';
import { Button } from '@chakra-ui/button';
import { Box, HStack, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';

export function Card({
  wide,
  connector,
  activeChainId,
  chainIds,
  isActivating,
  isActive,
  error,
  setError,
  ENSNames,
  accounts,
  provider,
  desiredChainId,
  isSendingETH,
  amount,
  toAddress,
  tokenAddress,
  tokenDecimals,
  icon,
  withSend,
  inline,
  onContinue,
  onPaid,
}) {
  const [ isPaying, setIsPaying ] = useState(false);
  const sendAsset = useCallback(async () => {
    if (!connector || !connector.provider) {
      console.error('Connector not initialized');
      return;
    }

    setIsPaying(true)
    const provider = new ethers.BrowserProvider(connector.provider);
    const signer = await provider.getSigner();

    try {
      if (isSendingETH) {
        const value = ethers.parseEther(amount.toString());
        const tx = await signer.sendTransaction({ to: toAddress, value });
        console.log('Transaction hash:', tx.hash);
        setIsPaying(false);
        onPaid?.(tx.hash);
      } else {
        // Replace the following with the token contract address
        const tokenABI = [
          // ERC20 standard ABI
          'function transfer(address to, uint256 value) external returns (bool)',
        ];
        const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
        const value = ethers.parseUnits(amount.toString(), Number(tokenDecimals));
        const tx = await tokenContract.transfer(toAddress, value);
        console.log('Transaction hash:', tx.hash);
        setIsPaying(false);
        onPaid?.(tx.hash);
      }
    } catch (e) {
      alert(e?.reason ?? e?.message ?? "Unknown error")
    }
  }, [isSendingETH, amount, toAddress, tokenAddress, tokenDecimals]);

  const connect = useCallback(async () => {
    if (desiredChainId === -1) {
      await connector.activate()
    } else if (
      connector instanceof WalletConnect
    ) {
      await connector.activate(desiredChainId)
    } else {
      await connector.activate(getAddChainParameters(desiredChainId))
    }

    withSend && sendAsset()
    onContinue?.()
  }, [connector, desiredChainId, sendAsset, withSend])

  return (
    <Button w={wide ? "full" : undefined} as={HStack} justifyContent="space-between" bg="gray.100" _hover={{ bg: "white" }} cursor="pointer" p={0} variant="outline" colorScheme="black" onClick={connect} leftIcon={<Box pl={2}><Image src={icon} w={8} h={8} /></Box>}>
      <Text flexGrow={1} pr={2} textAlign="left">{getName(connector)}</Text>
      <Box p={2} borderLeftWidth="1px" borderLeftColor="black">
        <Status isPaying={isPaying} isActivating={isActivating} isActive={isActive} error={error} />
      </Box>
    </Button>
  )
}
import { Box, Hide, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper } from "@chakra-ui/react"

const steps = [
  { title: 'Start', description: 'Intro' },
  { title: 'Chain', description: 'Select the chain' },
  { title: 'Sacrifice', description: 'Send your Sacrifice' },
]

export default function Steps({ index }) {
  return (
    <Stepper size={["sm","md"]} p={[2,4,8]} bg="linear-gradient(0deg, rgba(0,0,0,0.0) 0%, #ecd35033 1%, rgba(255,255,255,0.5) 10%, rgba(255,255,255,1) 95%, #ecd350cc 100%)" w="full" index={index} colorScheme="orange" borderTopRadius={[0,4,8]}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <Hide below="md"><StepDescription>{step.description}</StepDescription></Hide>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  )
}
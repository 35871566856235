import React, { useEffect } from "react";
import { Heading, Text, VStack, HStack, Box, Link } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import * as THREE from 'three';
import Meta from "../../_shared/firechain/Meta";
import Steps from "../comp/steps";

const vertexFragment = `
  varying vec2 vUv;
  uniform float u_ratio;
  uniform float u_time;
  uniform float u_speed;
  uniform float u_shape_offset;
  uniform float u_power;
  uniform float u_addition;

  // Simplex noise taken from
  // https://gist.github.com/patriciogonzalezvivo/670c22f3966e662d2f83
  vec3 permute(vec3 x) { return mod(((x*34.0)+1.0)*x, 289.0); }
  float snoise(vec2 v){
    const vec4 C = vec4(0.211324865405187, 0.366025403784439,
    -0.577350269189626, 0.024390243902439);
    vec2 i = floor(v + dot(v, C.yy));
    vec2 x0 = v - i + dot(i, C.xx);
    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
    i = mod(i, 289.0);
    vec3 p = permute(permute(i.y + vec3(0.0, i1.y, 1.0))
    + i.x + vec3(0.0, i1.x, 1.0));
    vec3 m = max(0.5 - vec3(dot(x0, x0), dot(x12.xy, x12.xy),
    dot(x12.zw, x12.zw)), 0.0);
    m = m*m;
    m = m*m;
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
    m *= 1.79284291400159 - 0.85373472095314 * (a0*a0 + h*h);
    vec3 g;
    g.x = a0.x * x0.x + h.x * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
  }

  // Color space transform taken from
  // https://gist.github.com/983/e170a24ae8eba2cd174f
  vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
  }

  const float STEPS = 4.;

  float get_noise(vec2 uv, float t){
    float SCALE = 8.;
    float noise = snoise(vec2(uv.x * SCALE, uv.y * .25 * SCALE - t));
    SCALE = 10.;
    noise += .2 * snoise(vec2(uv.x * SCALE + 1.5 * t, uv.y * .3 * SCALE));
    noise = min(1., .5 * noise + u_addition);
    return noise;
  }


  void main () {
    vec2 uv = vUv;
    uv.y /= u_ratio;

    float t = u_time * u_speed;

    float noise = get_noise(uv, t);
    float shape = pow(.8 * uv.y * u_ratio, .5);
    shape += 3. * pow(abs(uv.x - .5), 2.);
    shape *= u_shape_offset;

    float stepped_noise = floor(get_noise(uv, t) * STEPS) / STEPS;
    float d = pow(stepped_noise, u_power);
    d *= (1.2 - shape);

    vec3 hsv = vec3(d * .15, .8 - .2 * d, d + .5 + .1 * uv.y);
    vec3 col = hsv2rgb(hsv);

    col *= smoothstep(shape, shape + .2, noise);
    gl_FragColor = vec4(col, step(shape, noise) - .5);
  }
`

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = vec4(position, 1.);
}
`

function Complete() {
  useEffect(() => {
    const container = document.querySelector('#main');
    const canvasEl = document.querySelector('#canvas');

    let renderer, scene, camera, clock, material;

    const params = {
      speed: 2,
      shape: 1,
      power: .5,
      addition: .3,
    }

    initScene();
    window.addEventListener('resize', updateSceneSize);

    function initScene() {

        renderer = new THREE.WebGLRenderer({
            alpha: true,
            canvas: canvasEl
        });
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        scene = new THREE.Scene();
        camera = new THREE.OrthographicCamera(-.5, .5, .5, -.5, .1, 10);
        clock = new THREE.Clock()

        material = new THREE.ShaderMaterial({
            uniforms: {
                u_time: {type: "f", value: 0},
                u_ratio: {type: "f", value: window.innerWidth / window.innerHeight},
                u_speed: {type: "f", value: params.speed},
                u_shape_offset: {type: "f", value: params.shape},
                u_power: {type: "f", value: params.power},
                u_addition: {type: "f", value: params.addition},
            },
            vertexShader: vertexShader,
            fragmentShader: vertexFragment
        });
        const planeGeometry = new THREE.PlaneGeometry(2, 2);
        scene.add(new THREE.Mesh(planeGeometry, material));

        updateSceneSize();
        render();
    }


    function render() {
        material.uniforms.u_time.value = clock.getElapsedTime();
        renderer.render(scene, camera);
        requestAnimationFrame(render);
    }

    function updateSceneSize() {
        material.uniforms.u_ratio.value = window.innerWidth / window.innerHeight;
        renderer.setSize(container.clientWidth, container.clientHeight);
    }
  
    return () => {
    }
  }, [])

  return (
    <>
      <Meta title="Complete">
        Thank you for completing the Firechain Sacrifice
      </Meta>
      <VStack w="full" alignItems="flex-start">
        <VStack w="full" gap={[4,8,16]} maxW="7xl">
          <Heading as={HStack} fontSize="2xl">
            <Image w={8} h={8} src="/thefirechain.com/images/firechain-icon.png" alt="Firechain" />
            <Text as="span">
              Welcome to the Firechain Sacrifice Phase
            </Text>
          </Heading>
          <Text>
            The Firechain Sacrifice is creating a true decentralized ecosystem for and build by the people. Build from zero, with zero.
          </Text>

          <VStack mb={[4,8,16]} borderWidth="1px" bg="blackAlpha.50" borderColor="blackAlpha.500" borderRadius={[0,4,8]} w="full" alignItems="flex-start" gap={[1,2,4,8]}>          
            <Steps index={3} />

            <VStack w="full" p={[2,4,8]} gap={[2,4,8]}>
              
              <Text textAlign="center" fontSize="2xl" fontWeight="bold">
                Congratulations! You're now Fire&apos;d up!
              </Text>
              
            </VStack>

            <VStack p={[2,4,8]} w="full" alignItems="center">
              <Button colorScheme="green" size="lg" as={Link} href="/">
                Get more FIRE!
              </Button>
            </VStack>
          </VStack>
        </VStack>
      </VStack>

      <Box id="main" pointerEvents="none" pos="absolute" maxH="50vh" h="full" left={0} right={0} bottom={0}>
        <canvas id="canvas"></canvas>
      </Box>
    </>
  );
}

export default Complete;
